import React, { lazy, Suspense } from "react";
import Preloading from "./Preload/Preloading";

// Helper function for lazy loading
const LazyLoadingWrapping = (importFunc) => {
  const LazyComponent = lazy(importFunc);

  return (props) => (
    <Suspense fallback={<Preloading/>}>
      <LazyComponent {...props} />
    </Suspense>
  );
};
export default LazyLoadingWrapping;