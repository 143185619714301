import { configureStore } from '@reduxjs/toolkit';
import forceReloadReducer from '../reduxStore/forceReloadReducer';
import SettingsReducer from '../reduxStore/SettingsReducer';
import UserReducer from '../reduxStore/UserReducer';
import TranslateReducer from '../reduxStore/TranslateReducer';
import themeReducer from '../reduxStore/themeReducer';
import RolesReducer from '../reduxStore/RolesReducer';
import NotificationReducer from '../reduxStore/NotificationReducer';




export default configureStore({
  reducer: {
    forceReload: forceReloadReducer,
    settingsData:SettingsReducer,
    userData:UserReducer,
    translateData:TranslateReducer,
    themeData:themeReducer,
    rolesData:RolesReducer,
    notification:NotificationReducer
  },
});
