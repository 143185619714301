import { IconButton, Typography, Box } from "@mui/material";
import React from "react";
import { ImForward } from "react-icons/im";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "universal-cookie";
import helpers from "../../assets/js/helper";
import ReplyIcon from "@mui/icons-material/Reply";
import moment from "moment";
const cookies = new Cookies();

const NotificationCard = (props) => {
  const navigate = useNavigate();

  const onClickGo = (e) => {
    e?.preventDefault();
    navigate(props?.mainpath+"/Notifications", {
      state: {
        data: props,
      },
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",

        width: "300px",
        borderBottom: "1px  dashed  lightgray",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Cairo-Medium",
            fontSize: "14",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {props?.title ? props?.title : ""}
        </Typography>

        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            fontFamily: "Cairo",
            direction:'rtl',
          }}
        >
          <small style={{
            textAlign:'left'
          }}>
            {" "}
            {props?.created_at
              ? helpers.formatDate(props?.created_at)+' '+helpers.formatTime(props?.created_at)
              : ""}
          </small>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            fontFamily: "Cairo-Medium",
            fontSize: "12px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {props?.body ? props?.body : "لايوجد اشعارات"}
        </Typography>
        <Link
          to={{
            pathname: props?.mainpath+"/Notifications",
            state: { data: props },
          }}
        >
          <IconButton
            // onClick={onClickGo}
            sx={{ width: "40px", height: "40px" }}
          >
            <ImForward style={{ color: "#126A99", fontSize: "20px" }} />
          </IconButton>
        </Link>
      </Box>
    </Box>
  );
};

export default NotificationCard;
