import PathListRoutes from '../../Pages/PathListRoutes';
const VehiclePath = PathListRoutes?.find(item => item?.to == 'vehicles')
var ObjectOfErrors = {
  id: "",
  name: {
    value: "",
    error: false,
    message: "",
    required: false,
  },
  phone: {
    value: "",
    error: false,
    message: "",
    required: false,
  },
  user_phone: {
    value: "",
    error: false,
    message: "",
    required: false,
  },
  username: {
    value: "",
    error: false,
    message: "",
    required: false,
  },
  password: {
    value: "",
    error: false,
    message: "",
    required: false,
  },
  con_password: {
    value: "",
    error: false,
    message: "",
    required: false,
  },
  email: {
    value: "",
    error: false,
    message: "",
    required: false,
  },
  active: {
    value: true,
    error: false,
    message: "",
    required: false,
  },
  group_id: {
    value: null,
    error: false,
    message: "",
    required: false,
  },
  company_name: {
    value: '',
    error: false,
    message: "",
    required: false,
  },
  transfer_type_id: {
    value: '',
    error: false,
    message: "",
    required: false,
  },
  role_peer_user: {
    value: false,
    error: false,
    message: "",
    required: false,
  },
  role_name: {
    value: '',
    error: false,
    message: "",
    required: false,
  },
  user_id_number:{
    value: '',
    error: false,
    message: "",
    required: false,
  },
  default_access_page: {
    value: VehiclePath ? VehiclePath : {
      id: 5,
      roles: (rolesRedux, userData) => rolesRedux?.view_vehicle?.value,
      to: "vehicles",
    },
    error: false,
    message: "",
    required: false,
  }
};
export const resetErrors = (customerObject) => {
  var objectKeys = Object.keys(customerObject);

  var updatedObject = {
    ...customerObject,
  };
  try {
    objectKeys
      .filter((key) => key !== "id")
      .map((key) => {
        updatedObject[key]["error"] = false;
        updatedObject[key]["message"] = "";
      });
  } catch (error) { }

  return updatedObject;
};
export const checkErrors = (errorMessages, customerObject) => {
  const errorMessagesKeys = Object.keys(errorMessages);
  errorMessagesKeys.map((key) => {
    if (!key?.includes("contacts")) {
      let messages = [];
      errorMessages[key].map((message) => {
        messages.push(message);
      });

      customerObject[key]["error"] = true;
      customerObject[key]["message"] = messages;
    }
  });

  return customerObject;
};
export const ObjectMerge = (updatedObject, number) => {
  if (number == 1) {
    ObjectOfErrors = {
      id: updatedObject?.id,
      name: {
        value: updatedObject?.name,
        error: false,
        message: "",
        required: false,
      },
      username: {
        value: updatedObject?.username,
        error: false,
        message: "",
        required: false,
      },
      password: {
        value: "",
        error: false,
        message: "",
        required: false,
      },
      con_password: {
        value: "",
        error: false,
        message: "",
        required: false,
      },
      email: {
        value: updatedObject?.email,
        error: false,
        message: "",
        required: false,
      },
      phone: {
        value: updatedObject?.phone,
        error: false,
        message: "",
        required: false,
      },
      user_phone: {
        value: updatedObject?.user_phone,
        error: false,
        message: "",
        required: false,
      },
      active: {
        value: updatedObject?.active,
        error: false,
        message: "",
        required: false,
      },
      group_id: {
        value: updatedObject?.group_id,
        error: false,
        message: "",
        required: false,
      },
      company_name: {
        value: updatedObject?.company_name,
        error: false,
        message: "",
        required: false,
      },
      default_access_page: {
        value: updatedObject?.default_access_page,
        error: false,
        message: "",
        required: false,
      },
      transfer_type_id: {
        value: updatedObject?.transfer_type_id,
        error: false,
        message: "",
        required: false,
      },
      role_peer_user: {
        value: updatedObject?.role_peer_user,
        error: false,
        message: "",
        required: false,
      },
      role_name: {
        value: updatedObject?.role_name,
        error: false,
        message: "",
        required: false,
      },
      user_id_number:{
        value: updatedObject?.user_id_number,
        error: false,
        message: "",
        required: false,
      },
    };
  } else {
    ObjectOfErrors = {
      id: '',
      name: {
        value: '',
        error: false,
        message: "",
        required: false,
      },
      username: {
        value: '',
        error: false,
        message: "",
        required: false,
      },
      password: {
        value: "",
        error: false,
        message: "",
        required: false,
      },
      con_password: {
        value: "",
        error: false,
        message: "",
        required: false,
      },
      email: {
        value: '',
        error: false,
        message: "",
        required: false,
      },
      phone: {
        value: '',
        error: false,
        message: "",
        required: false,
      },
      user_phone: {
        value: "",
        error: false,
        message: "",
        required: false,
      },
      active: {
        value: false,
        error: false,
        message: "",
        required: false,
      },
      group_id: {
        value: null,
        error: false,
        message: "",
        required: false,
      },
      company_name: {
        value: '',
        error: false,
        message: "",
        required: false,
      },
      transfer_type_id: {
        value: '',
        error: false,
        message: "",
        required: false,
      },
      role_peer_user: {
        value: false,
        error: false,
        message: "",
        required: false,
      },
      role_name: {
        value: '',
        error: false,
        message: "",
        required: false,
      },
      user_id_number:{
        value: '',
        error: false,
        message: "",
        required: false,
      },
      default_access_page: {
        value: VehiclePath ? VehiclePath : {
          id: 5,
          roles: (rolesRedux, userData) => rolesRedux?.view_vehicle?.value,
          to: "vehicles",
        },
        error: false,
        message: "",
        required: false,
      }
    };
  }

  return ObjectOfErrors;
};
export const restCustomeObject = (customerObject, obj) => {
  // var objectKeys = Object.keys(customerObject);

  try {
    customerObject[obj]["error"] = false;
    customerObject[obj]["message"] = "";
  } catch (error) { }

  return customerObject;
};
export const setCustomObject = (name, value) => {
  console.log("setCustomObject==>", name, "-", value);
  ObjectOfErrors = {
    ...ObjectOfErrors,
    [name]: {
      ...ObjectOfErrors[name],
      value: value,
      error: false,
      message: "",
    },
  };
  console.log("setCustomObject==>", ObjectOfErrors);

  return ObjectOfErrors;
};
export const setCustomObjectError = (name, value) => {
  console.log("setCustomObject==>", name, "-", value);
  ObjectOfErrors = {
    ...ObjectOfErrors,
    [name]: {
      ...ObjectOfErrors[name],
      error: value ? true : false,
      message: [value],
    },
  };
  console.log("setCustomObject==>", ObjectOfErrors);

  return ObjectOfErrors;
};

export default ObjectOfErrors;
