const PathListRoutes = [
    {
        id: 1,
        roles: (rolesRedux, userData) => rolesRedux?.dashboard?.value,
        to: "dashboard",
        name:'لوحة التحكم',
    },
    {
        id: 2,
        roles: (rolesRedux, userData) => true,
        to: "user-profile",
        name:'الصفحة الشخصية للمستخدم'
    },
    {
        id: 3,
        roles: (rolesRedux, userData) => rolesRedux?.view_user?.value,
        to: "users",
        name:'المستخدمين'
    },
    {
        id: 4,
        roles: (rolesRedux, userData) => true,
        to: "landing",
        name:'واجهة الترحيب'
    },
    {
        id: 5,
        roles: (rolesRedux, userData) => rolesRedux?.view_vehicle?.value,
        to: "vehicles",
        name:'الاليات'
    },
    {
        id: 6,
        roles: (rolesRedux, userData) => rolesRedux?.view_order?.value,
        to: "orders",
        name:'الطلبات'
    },
    {
        id: 7,
        roles: (rolesRedux, userData) => rolesRedux?.view_group?.value ,
        to: "groups",
        name:'المجموعات'
    },
    {
        id: 8,
        roles: (rolesRedux, userData) => rolesRedux?.view_vehicle_plate_type?.value ,
        to: "vehicle-plate-types",
        name:'انواع اللوحات'
    },
    {
        id: 9,
        roles: (rolesRedux, userData) => rolesRedux?.view_driver_id_type?.value,
        to: "driver-id-types",
        name:'انواع هوية المستخدم'
    },
    {
        id: 10,
        roles: (rolesRedux, userData) => rolesRedux?.view_vehicle_type?.value,
        to: "vehicle-types",
        name:'انواع المركبات'
    },
    {
        id: 11,
        roles: (rolesRedux, userData) => rolesRedux?.view_vehicle_plate_government?.value,
        to: "vehicle-plate-governments",
        name:'المحافظات للوحات'
    },
    {
        id: 12,
        roles: (rolesRedux, userData) => rolesRedux?.view_vehicle_plate_letter?.value,
        to: "vehicle-plate-letter",
        name:'الحروف للوحات'
    },
    {
        id: 13,
        roles: (rolesRedux, userData) => rolesRedux?.view_takeel_issue_source?.value,
        to: "takeel-issue-sources",
        name:'جهات التكييل'
    },
    {
        id: 14,
        roles: (rolesRedux, userData) => rolesRedux?.add_order?.value,
        to: "new-order",
        default:true,
        name:'طلب جديد'
    },
    {
        id: 15,
        roles: (rolesRedux, userData) => rolesRedux?.update_order?.value,
        to: "copy-order",
        name:'نسخ الطلب'
    },
    {
        id: 16,
        roles: (rolesRedux, userData) => rolesRedux?.view_transfer_type?.value,
        to: "transfer-types",
        name:'طراز المركبات'
    },
    {
        id: 17,
        roles: (rolesRedux, userData) => rolesRedux?.show_notification?.value,
        to: "notifications",
        name:'الاشعارات'
    },
    {
        id: 21,
        roles: (rolesRedux, userData) => rolesRedux?.update_order?.value,
        to: "edit-order",
        name:'تعديل الطلب'
    },
    {
        id: 22,
        roles: (rolesRedux, userData) => rolesRedux?.show_entity_type?.value,
        to: "entity-types",
        name:'انواع الجهات'
    },
    {
        id: 28,
        roles: (rolesRedux, userData) => rolesRedux?.show_log?.value,
        to: "activities",
        name:'سجل النشاطات'
    },
    {
        id: 29,
        roles: (rolesRedux, userData) => rolesRedux?.add_or_edit_order_setting?.value,
        to: "order-settings",
        name:'اعدادات الطلب'
    }
];
export default PathListRoutes;