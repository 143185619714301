import callAPI from "../ApiConfig";
import store from "../../app/store";

const index = async (props) => {
  const storeState = store.getState();
  return await callAPI({
    route: "notifications",
    method: "get",
    signal: props?.signal,
    params: props?.params,
    headers: {
      Authorization: `Bearer ${storeState?.userData?.token}`,
    },
  });
};
const list = async (props) => {
  const storeState = store.getState();

  return await callAPI({
    route: "notifications_list",
    method: "get",
    signal: props?.signal,
    params: props?.params,
    headers: {
      Authorization: `Bearer ${storeState?.userData?.token}`,
    },
  });
};
const insert = async (props) => {
  const storeState = store.getState();

  return await callAPI({
    route: "notifications",
    method: "post",
    signal: props?.signal,
    params: props?.params,
    data: props?.data,
    headers: {
      Authorization: `Bearer ${storeState?.userData?.token}`,
    },
  });
};
const update = async (props) => {
  const storeState = store.getState();

  return await callAPI({
    route: "notifications/"+props?.id,
    method: "put",
    signal: props?.signal,
    params: props?.params,
    data: props?.data,
    headers: {
      Authorization: `Bearer ${storeState?.userData?.token}`,
    },
  });
};
const destroy = async (props) => {
  const storeState = store.getState();

  return await callAPI({
    route: "notifications/"+props?.id,
    method: "delete",
    signal: props?.signal,
    headers: {
      Authorization: `Bearer ${storeState?.userData?.token}`,
    },
  });
};
const unReadedNotifications = async (props) => {
  const storeState = store.getState();

  return await callAPI({
    route: "unreaded_notifications",
    method: "get",
    signal: props?.signal,
    params:props?.params,
    data:props?.data,
    noLoading:props?.noLoading,
    headers: {
      Authorization: `Bearer ${storeState?.userData?.token}`,
    },
  });
};
const setNotificationsAsRead = async (props) => {
  const storeState = store.getState();
  return await callAPI({
    route: "set_ids_as_read",
    method: "patch",
    signal: props?.signal,
    params:props?.params,
    data:props?.data,
    noLoading:props?.noLoading,
    headers: {
      Authorization: `Bearer ${storeState?.userData?.token}`,
    },
  });
};
const notificationAPI = {
  index: index,
  list: list,
  store: insert,
  update: update,
  destroy: destroy,
  unReadedNotifications:unReadedNotifications,
  setNotificationsAsRead:setNotificationsAsRead
};
export default notificationAPI;
