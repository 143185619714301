import React, { useRef, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import axios from "axios";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import logoComapny from "../../assets/img/logo.png";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Navigate, useNavigate } from "react-router-dom";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import "./Login.css";
import Preloading from "../Preload/Preloading";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import {I18nextProvider} from "react-i18next";
// import i18next from "i18next";
import { useMemo } from "react";
import { useEffect } from "react";
import helpers from "../../assets/js/helper";
import { useTranslation } from "react-i18next";
import userAPI from '../../Network/User/index'
import moment from "moment";
import employeeImage from '../../assets/img/user.png'
import { setUserToken, setUserData } from "../../reduxStore/UserReducer";
import ObjectOfErrors, { checkErrors, resetErrors, restCustomeObject, setCustomObjectError } from '../../Errors/Users/User.js'
import { Checkbox, FormControlLabel } from "@mui/material";

const cookies = new Cookies();
const language = cookies.get("language") ? cookies.get("language") : "ar";

const Login = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const controller = new AbortController();
  const [showPasword, setShowPassword] = useState(false);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState('')
  const [isUserName, setIsUserName] = useState(true)
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [ErrorsObject, setErrorObject] = useState(ObjectOfErrors)
  const mainPath = useSelector(state => state.settingsData.mainpath)
  const globalTheme = useSelector(state => state.themeData.globalTheme)
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const phoneRef = useRef(null);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });
    const handleAutoFill = (event) => {
      if (event.animationName === "onAutoFillStart") {
        // Check if the username field is autofilled
        if (
          usernameRef &&
          usernameRef?.current &&
          usernameRef.current.value !== ""
        ) {
          usernameRef.current.focus();
        }
        if (
          phoneRef &&
          phoneRef?.current &&
          phoneRef.current.value !== ""
        ) {
          phoneRef.current.focus();
        }
        // Check if the password field is autofilled
        if (
          passwordRef &&
          passwordRef?.current &&
          passwordRef.current.value !== ""
        ) {
          passwordRef.current.focus();
        }
      }
    };

    document.addEventListener("animationstart", handleAutoFill);
    return () => {
      document.removeEventListener("animationstart", handleAutoFill);
    };
  }, []);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      if (event.target === usernameRef.current) {
        console.log("sdfsdfsdfsdfsdfsdfsdfds");
        passwordRef.current.focus();
      } else if (event.target === phoneRef.current) {
        console.log("sdfsdfsdfsdfsdfsdfsdfds");
        passwordRef.current.focus();
      }
    }
  };

  const getImageProfile = (images) => {
    let imageUrl = null;
    let lastImageProf = null;
    images?.length &&
      images?.map((itm) => {
        if (itm?.image_type_id == 1) {
          lastImageProf = itm?.image_url;
        }
      });
    if (lastImageProf) imageUrl = lastImageProf;

    console.log('image_Pro', imageUrl)
    return process.env.REACT_APP_API_URL_IMAGE + imageUrl;
  };

  const login = async () => {
    console.log("login");
    if (phone && !validatePhoneNumber(phone)) {
      return
    }
    if (!phone && !username) {
      toast.warning(t("ERROR.login_username_error"));
      return
    }
    if (!password) {
      toast.warning(t("ERROR.login_userpassword_error"));
      return
    }
    setLoading(true)
    console.log("login1");

    const result = await userAPI.login({
      data: {
        username: phone ? null : username,
        password: password,
        phone: phone
      },
      signal: controller?.signal,
    })
    console.log('fasdfasdfas', result)
    setLoading(false)

    if (result.status && result?.status) {
      cookies.set("token", result?.data?.data?.token, { path: "/" })
      cookies.set("token_date", moment(), { path: "/" })
      cookies.set("remember_me", rememberMe ? 1 : 0, { path: "/" })
      cookies.set("user_id", result?.data?.data?.user?.id, { path: "/" })
      let groupData = null
      if (result?.data?.data?.user?.groups?.length) {
        groupData = {
          id: result?.data?.data?.user?.groups[0]?.id,
          name: result?.data?.data?.user?.groups[0]?.name,
        }
      }
      let userObject = {
        username: result?.data?.data?.user?.username,
        companyName: result?.data?.data?.user?.company_name,
        name: result?.data?.data?.user?.name,
        image_url: result?.data?.data?.user?.images?.length ? getImageProfile(result?.data?.data?.user?.images) : employeeImage,
        email: result?.data?.data?.user?.email,
        phone: result?.data?.data?.user?.phone,
        group: groupData
      }
      var roles = []
      if (result?.data?.data?.user && result?.data?.data?.user?.groups?.length)
        roles = result?.data?.data?.user?.groups[0].roles;
      localStorage.setItem('roles', JSON.stringify(roles?.map(itm => itm?.id)))
      dispatch(setUserData(userObject))
      dispatch(setUserToken(result?.data?.data?.token));
      localStorage.setItem('userData', JSON.stringify(userObject))

      // cookies.set("token", sha256(userName + userPassword), { path: "/" })
      cookies.set('access_type', 'admin', { path: "/" })
      cookies.set("default_route", result?.data?.data?.user?.default_access_page, { path: "/" });
      // navigate("ticket-system/"+default_route);
      setTimeout(() => {
        setLoading(false)
      }, 4000)
    }
    else {
      if (typeof result?.errorMessage === "object") {
        let updatedObject = resetErrors(ErrorsObject)
        setErrorObject(checkErrors(result.errorMessage, updatedObject))
        toast.error(t('NETWORKMESSAGE.messageError'))
      }
      else if (typeof result?.errorMessage === "string") {
        toast.error(result?.errorMessage)
      } else {
        toast.error(t('NETWORKMESSAGE.messageError'))
      }
    }
    setLoading(false)
  };

  // useEffect(()=>{})

  function validatePhoneNumber(phoneNumber) {
    if (phoneNumber?.length <= 0) {
      setErrorObject(setCustomObjectError('phone', ''))
      return true;
    }
    var phonePattern = /^(075|078|077|079)\d{8}$/;
    if (phonePattern.test(phoneNumber)) {
      setErrorObject(setCustomObjectError('phone', ''))
      return true;
    } else {
      setErrorObject(setCustomObjectError('phone', 'رقم الهاتف يجب ان يكون بهذه الصيغة ex:07x-x-xxxx-xxx'))
      return false;
    }
  }

  if (cookies.get("token")) {
    if (cookies.get("default_route"))
      navigate(mainPath +'/'+ cookies.get("default_route"));
    else
      navigate(mainPath + "/landing");
  } else
    return (
      <LoginPage className="LoginPage">
        {loading ? <Preloading /> : null}

        <CustomeLoginWrapper screenWidth={screenWidth}>
          <CustomeLeft screenWidth={screenWidth}>
            <Box
              sx={{
                "& span": {
                  // fontFamily: "'Lobster', cursive",
                },
                display: "flex",
              }}
            >
              <p
                className="titleName"
                style={{
                  color: globalTheme.mainColor,
                  fontSize: "25px",
                  marginLeft: "10px",
                  fontFamily: "Cairo !important",
                }}
              >
                نظام
              </p>
              <p
                className="titleName"
                style={{
                  color: globalTheme.secondColor,
                  fontSize: "25px",
                  fontFamily: "Cairo  !important",
                }}
              >
                منصة تكييل المركبات
              </p>
            </Box>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                width: screenWidth <= 1000 ? '91.5%' : "80%",
                top: "38px",
                "& span,& p": {
                  fontFamily: "Cairo !important",
                },
                "& .MuiInputBase-input": {
                  fontFamily: "Cairo !important",
                },

              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: "28px",
                  fontWeight: "bold",
                  position: "relative",
                  top: 30,
                  display: "flex",
                  justifyContent: "start",
                  fontFamily: "Cairo",
                }}
              >
                تسجيل الدخول
              </Typography>
              <Box sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                height: "20px",
                marginTop: "60px",
              }}>
                <FormControlLabel control={<Checkbox checked={isUserName} onChange={(e) => {
                  setIsUserName(e?.target?.checked);
                }} />} label="تسجيل باستخدام اسم المستخدم" />
              </Box>
              {!isUserName ?
                <TextField
                  sx={{
                    mt: 1,
                    width: "100%",
                    "& .muiltr-9ddj71-MuiInputBase-root-MuiOutlinedInput-root": {
                      // left:'30px'
                      borderRadius: "10px",
                    },
                    "& .muiltr-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                      left: "15px",
                    },
                    "& .muiltr-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      padding: "0 20px",
                    },

                    fontFamily: "Cairo",
                  }}
                  error={ErrorsObject?.phone?.error}
                  helperText={ErrorsObject?.phone?.message?.length ? ErrorsObject?.phone?.message[0] : ''}
                  id="outlined-error-helper-text"
                  label={"رقم الهاتف"}
                  defaultValue=""
                  value={phone}
                  inputRef={phoneRef}
                  autoComplete="new-password"
                  onKeyDown={handleKeyPress}

                  onChange={(e) => {
                    if (!isNaN(e?.target.value)) {
                      setPhone(e.target.value);
                      validatePhoneNumber(e.target.value)
                    }
                  }}
                  inputProps={{ style: { fontFamily: 'Cairo' } }}
                  required
                /> : <TextField
                  sx={{
                    mt: 1,
                    width: "100%",
                    "& .muiltr-9ddj71-MuiInputBase-root-MuiOutlinedInput-root": {
                      // left:'30px'
                      borderRadius: "10px",
                    },
                    "& .muiltr-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                      left: "15px",
                    },
                    "& .muiltr-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      padding: "0 20px",
                    },

                    fontFamily: "Cairo",
                  }}
                  // error={ErrorsObject?.username?.error}
                  // helperText={ErrorsObject?.username?.value}
                  id="outlined-error-helper-text"
                  label={"اسم المستخدم"}
                  defaultValue=""
                  value={username}
                  onKeyDown={handleKeyPress}

                  inputRef={usernameRef}
                  autoComplete="new-password"
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                  inputProps={{ style: { fontFamily: 'Cairo' } }}
                  required
                />}
              <Box sx={{ position: "relative" }}>
                <TextField
                  sx={{
                    mt: 2,
                    width: "100%",
                    fontFamily: "Cairo",
                    "& .muiltr-9ddj71-MuiInputBase-root-MuiOutlinedInput-root": {
                      // left:'30px'
                      borderRadius: "10px",
                    },
                    "& .muiltr-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                      left: "15px",
                    },
                    "& .muiltr-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      padding: "0 20px",
                    },
                  }}
                  id="outlined-error-helper-text"
                  error={ErrorsObject?.password?.error}
                  helperText={ErrorsObject?.password?.value}
                  label={"كلمة السر"}
                  type={showPasword ? "text" : "password"}
                  defaultValue=""
                  value={password}
                  inputRef={passwordRef}
                  onKeyDown={(event) => {
                    if (event.key == "Enter") {
                      login();
                    }
                  }}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  required
                />
                {!showPasword ? (
                  <VisibilityOutlinedIcon
                    sx={{
                      position: "absolute",
                      top: "33px",
                      color: "#958a8a",
                      right: "5%",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowPassword(true)}
                  />
                ) : (
                  <VisibilityOffOutlinedIcon
                    sx={{
                      position: "absolute",
                      top: "33px",
                      right: "5%",
                      color: "#958a8a",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowPassword(false)}
                  />
                )}
              </Box>
            </Box>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                mt: 8,
              }}
            >
              <Button
                id="sign_in_div_2"
                variant="contained"
                disabled={(!username && !phone) || !password}
                sx={{
                  backgroundColor: globalTheme.secondColor,
                  color: "white",
                  fontSize: 18,
                  outline: "none !important",
                  border: "none !important",
                  fontWeight: "500",
                  borderRadius: "5px",
                  width: screenWidth <= 1000 ? '91.5%' : "80%",
                  padding: "9px 2px",
                  textTransform: "uppercase",
                  fontFamily: "Cairo",
                  "&:hover": {
                    backgroundColor: globalTheme.secondColor + "a0",
                  },

                  "& span": {
                    textTransform: "lowercase",
                  },
                }}
                onClick={login}
              >
                تسجيل دخول
              </Button>
            </Box>
          </CustomeLeft>
          <CustomeRight
            screenWidth={screenWidth}
            globalTheme={globalTheme}
          >
            <svg
              className="absolute inset-0 pointer-events-none"
              viewBox="0 0 960 540"
              width="100%"
              height="100%"
              preserveAspectRatio="xMidYMax slice"
              xmlns="http://www.w3.org/2000/svg"
              style={{ color: "#b4b4b463", position: "absolute" }}
              fill="#0367a4"
            >
              <g
                className="opacity-20 MuiBox-root muiltr-1wnrr1p"
                fill="none"
                stroke="currentColor"
                stroke-width="100"
              >
                <circle r="234" cx="196" cy="23"></circle>
                <circle r="234" cx="790" cy="491"></circle>
              </g>
            </svg>
            <svg
              style={{
                color: "#ffffffa8",
                position: "absolute",
                top: "-100px",
                left: "400px",
                right: -100,
                // transform: "scale(0.779)",
              }}
              className="absolute -top-64 -left-64 opacity-20 MuiBox-root muiltr-x3mbcd"
              viewBox="0 0 220 192"
              fill="#b4b4b463"
            >
              <defs>
                <pattern
                  id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="220"
                height="192"
                fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
              ></rect>
            </svg>
            <Box
              sx={{
                zIndex: 1000,
                width: "100%",
                height: "100%",
                margin: "230px 0 0 60px",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: "#fff",
                  fontSize:
                    screenWidth <= 1600 ? (screenWidth <= 1400 ? 30 : 30) : 40,
                  fontWeight: "bold",
                  letterSpacing: 2,
                  display: "flex",
                  justifyContent: "start",
                  textAlign: "left",
                  fontFamily: "Cairo",
                }}
              >
                أهلآ بك في <br />
                منصة تكييل المركبات
              </Typography>

              <Typography
                variant="span"
                sx={{
                  color: "#fff",
                  fontSize:
                    screenWidth <= 1600 ? (screenWidth <= 1400 ? 12 : 15) : 17,
                  width: "95%",
                  position: "relative",
                  top: 30,
                  display: "flex",
                  justifyContent: "start",
                  textAlign: "left",
                  wordBreak: " keep-all;",
                  wordWrap: " break-word",
                  whiteSpace: "break-word",
                }}
              >
                مرحبًا بك في منصة تكييل المركبات! نحن نوفر حلاً رقميًا سهلًا وسريعًا لتكييل مركباتك بكل يسر وسهولة.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                // alignItems: "center",
                position: "absolute",
                left: "35%",
                bottom: "15%",
                // borderRadius: "50% !important",
                // backgroundColor: "#fff",
                // width: "110px",
                // height: "110px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // position: "absolute",
                  // left: "35%",
                  // bottom: "15%",
                  borderRadius: "50% !important",
                  backgroundColor: "#fff",
                  width: "110px",
                  height: "110px",
                  overflow: "hidden"
                }}
                className="cir"
              >
                <img
                  src={logoComapny}
                  style={{
                    width: "90px",
                    height: "90px",
                    backgroundSize: "contain",
                    backgroundPosition: "center center",
                    objectFit: "scale-down",
                  }}
                />
              </Box>
              <span style={{ marginTop: '15px', color: '#fff', position: 'relative', left: '40px' }}>شركة توزيع المنتجات النفطية</span>
            </Box>
          </CustomeRight>
        </CustomeLoginWrapper>
        <div className="area">
          <ul className="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </LoginPage>
    );
};
const LoginPage = styled(Box)(({ theme }) => ({
  backgroundColor: "#e1e3e5",
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
}));
const CustomeLoginWrapper = styled(Box)(({ theme, screenWidth }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#fff",
  width: screenWidth <= 600 ? "100%" : "70%",
  height: screenWidth <= 600 ? "100%" : "80%",
  borderRadius: screenWidth <= 600 ? 0 : 20,
  boxShadow: "-1.5px 2px 4px -3px rgba(0,0,0,0.75)",
  overflow: "hidden",
  zIndex: 1000,
  paddingTop: screenWidth <= 600 ? "30px" : "0",
}));

const CustomeLeft = styled(Box)(({ theme, screenWidth }) => ({
  display: "flex",
  justifyContent: "start",
  flexDirection: "column",
  alignItems: "start",
  padding: "30px 0 0 50px",
  width: screenWidth <= 1000 ? "100%" : "50%",
  height: "100%",
  "& .imgLogo": {
    width: "300px",
    height: "40px",
    backgroundSize: "cover",
    borderRadius: "50% !important",
  },
}));

const CustomeRight = styled(Box)(({ theme, screenWidth, globalTheme }) => ({
  display: screenWidth <= 1000 ? "none" : "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "50%",
  height: "100%",
  backgroundColor: globalTheme.mainColor,
  position: "relative",
  overflow: "hidden",
}));

const CustomeImageList = styled(Box)(({ theme, screenWidth }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  overflow: "hidden",
  "& .imgItme": {
    width: screenWidth < 1600 ? "46px" : "50px",
    height: screenWidth < 1600 ? "46px" : "50px",
    borderRadius: 50,
    position: "relative",
    outline: "3px solid #006CAD",
  },
  "& .img1": {
    //   left:10,
    zIndex: 60,
  },
  "& .img2": {
    left: "-8px",
    zIndex: 50,
  },
  "& .img3": {
    left: "-15px",
    zIndex: 40,
  },
  "& .img4": {
    zIndex: 30,
    left: "-23px",
  },
}));

const CustomeLanguageDrowpDown = styled(Box)(({ theme, screenWidth }) => ({
  position: "absolute",
  top: 7,
  left: 15,
  width: "100px",
  height: "40px",
  backgroundColor: screenWidth <= 600 ? "rgba(245,247,245,0.7)" : "#FFFFFF",
  borderRadius: 7,
  display: "flex",
  // justifyContent:'center',
  alignItems: "center",
  zIndex: 2000,
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: "#fff",
    color: "#000",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    "& .itmlist:hover": {
      backgroundColor: "rgba(255,255,255,0.5)",
    },
  },
}));
export default Login;
