
import axios from "axios";
import store from '../app/store';
import Cookies from "universal-cookie";
import { setUserToken } from "../reduxStore/UserReducer";
import { setGlobalLoading } from "../reduxStore/SettingsReducer";
import { toast } from "react-toastify";

const cookies = new Cookies();

const APP_CONSTANTS = {
    API_URL: `${process.env.REACT_APP_API_URL}`,
}


const callAPI = async (parameters) => {

    const storeState = store.getState();
    console.log("storeState", storeState);



    try {
        if (!parameters?.noLoading)
            store.dispatch(setGlobalLoading(true))

        const result = await axios({
            url: APP_CONSTANTS.API_URL + parameters?.route,
            method: parameters?.method,
            signal: parameters.signal,
            headers: {
                Accept: "application/json",
                ...parameters?.headers,
                'X-localization': 'ar',
            },
            params: parameters?.params,
            data: parameters?.data,
            ...parameters?.otherParams
        })
        store.dispatch(setGlobalLoading(false))

        if (result && result.status == 200) {

            if (result && result?.data) {
                
                return { fromAPI: true, status: true, data: result.data, error: false, errorCode: 0, errorMessage: null,blob:result?.blob?result?.blob:null };
            }
            return { fromAPI: true, status: false, data: null, error: true, errorCode: 1, errorMessage: "" };

        } else if (result.data.status === false) {
            return { fromAPI: true, status: false, data: null, error: true, errorCode: 2, errorMessage: "" };
        }
    } catch (error) {
        store.dispatch(setGlobalLoading(false))

        const responseResult = error.response
        console.log('asdsadsadsadasdas====>', responseResult)


        if (responseResult && responseResult.status === 400) {
            if (responseResult.data.data && typeof responseResult.data.data == 'string' && responseResult.data.data?.includes('Unauthenticated')) {
                cookies.remove("user")
                cookies.remove("token")
                cookies.remove("default_route")
                store.dispatch(setUserToken(null))
            }
            else return { fromAPI: true, status: false, data: null, error: true, errorCode: 4, errorMessage: responseResult.data.data };
        }
        else if (responseResult && responseResult?.status === 401) {
            if (!parameters?.is_quest_page) {
                cookies.remove("user")
                cookies.remove("token")
                cookies.remove("default_route")
                store.dispatch(setUserToken(null))
                if (parameters?.route && parameters?.route != 'login' && responseResult && responseResult?.data?.data && typeof responseResult?.data?.data == 'string') {
                    toast.warn(responseResult?.data?.data)
                    localStorage.setItem('messageRequest', responseResult?.data?.data)
                } else if (parameters?.route && parameters?.route != 'login') {
                    toast.warn(responseResult?.statusText)
                    localStorage.setItem('messageRequest', responseResult?.statusText)
                }
                if (parameters?.route && parameters?.route != 'login')
                    window.location.href = '/'
            }

            if (responseResult && responseResult?.data?.data && typeof responseResult?.data?.data == 'string')
                return { fromAPI: true, status: false, data: null, error: true, errorCode: 401, errorMessage: responseResult?.data?.data };
            else if (responseResult?.statusText && typeof responseResult?.statusText == 'string') {
                return { fromAPI: true, status: false, data: null, error: true, errorCode: 401, errorMessage: responseResult?.statusText };
            } else
                return { fromAPI: true, status: false, data: null, error: true, errorCode: 401, errorMessage: responseResult.data.message };


        }
        else if (responseResult && responseResult.status === 500) {

            return { fromAPI: true, status: false, data: null, error: true, errorCode: 500, errorMessage: responseResult.data?.message };
        }
        else {
            return { fromAPI: true, status: false, data: null, error: true, errorCode: 6, errorMessage: "" };

        }
    }

}

export default callAPI;