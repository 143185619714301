import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Divider, ListItemIcon, ListItemText, MenuList } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function PopupProfile({
  anchorEl,
  open,
  handleClick,
  handleClose,
  ...props
}) {
  const navigate = useNavigate();
  const [t] = useTranslation("common");
  const mainpath=useSelector(state=>state.settingsData.mainpath)

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <Box
        sx={{
          borderBottom: "1px dashed lightgray",
          px: 1,
          py: 1,
          "& .MuiTypography-root": {
            fontFamily: "Cairo",
          },
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span style={{ fontFamily: "Cairo-Bold", marginLeft: "5px" }}>
          {localStorage.getItem("userData")
            ? JSON.parse(localStorage.getItem("userData"))?.username
            : ""}
        </span>
        <span
          style={{ fontFamily: "Cairo", marginLeft: "5px", fontSize: "12px" }}
        >
          {" "}
          {localStorage?.getItem("userData")
            ? JSON.parse(localStorage?.getItem("userData"))?.email
            : ""}
        </span>
      </Box>
      <MenuList
        sx={{
          py: 2,
          px: 1,
          border: "none !important",
          outline: "none !important",
          "& .MuiTypography-root": {
            fontFamily: "Cairo",
          },
          "& .MuiButtonBase-root": {
            padding: "5px 5px !important",
            margin: "0px 0px !important",
            width: "150px",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate(`${mainpath}/user-profile`);
          }}
        >
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('APPBAR.profile')}</ListItemText>
          {/* <Typography variant="body2" color="text.secondary">
            ⌘X
          </Typography> */}
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            navigate(`${mainpath}/default_settings`);
          }}
        >
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('APPBAR.settings')}</ListItemText>
        </MenuItem> */}
      </MenuList>
      <Box
        sx={{
          borderTop: "1px dashed lightgray",
          px: 1,
          py: 1,
          "& .MuiTypography-root": {
            fontFamily: "Cairo",
          },
        }}
      >
        <MenuItem onClick={props?.logout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('APPBAR.logout')}</ListItemText>
        </MenuItem>
      </Box>
    </Menu>
  );
}
