import { toast } from "react-toastify";

const helpers = {
  stringToColour: function stringToColour(str) {
    var colour = "#fff";
    if (str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      colour = "#";
      for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xff;
        colour += ("00" + value.toString(16)).substr(-2);
      }
    }

    return colour;
  },
  recursionCategory: function recursionCategory(data) {
    var parent = [];
    for (let item = 0; item < data.length; item++) {
      var child = {
        name: data[item].name,
        attributes: {
          id: data[item].id,
          color: helpers.stringToColour(data[item].condition_value),
          default_value: data[item].default_value,
          is_mandatory: data[item].is_mandatory,
          is_multiple_select: data[item].is_multiple_select,
          has_condition: data[item].has_condition,
          condition_value: data[item].condition_value,
          field_type_name: data[item]["field_type"].name,
          field_type_has_list: data[item]["field_type"].has_list,
        },
      };

      var children = data[item].children;
      var childrenMapped = null;
      if (children != null && children.length > 0) {
        childrenMapped = recursionCategory(children);
      }
      parent.push({
        ...child,
        children: childrenMapped,
      });
    }
    return parent;
  },
  listToTree: function listToTree(items, id = null, link = "survey_field_id") {
    return items
      .filter((item) => item[link] === id)
      .map((item) => ({
        ...item,
        children: helpers.listToTree(items, item.id),
      }));
  },
  checkDarknessOrLightnessForColor: function checkDarknessOrLightnessForColor(
    color
  ) {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color?.match(/^rgb/)) {
      // If RGB --> store the red, green, blue values in separate variables
      color = color?.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
      );

      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      // If hex --> Convert it to RGB: http://gist.github.com/983661
      color = +(
        "0x" + color?.slice(1).replace(color?.length < 5 && /./g, "$&$&")
      );

      r = color >> 16;
      g = (color >> 8) & 255;
      b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
      return false; //light
    } else {
      return true; //dark
    }
  },
  formatDate: function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  },
  formatTime: function formatTime(date, notHasSeconds) {
    var d = new Date(date),
      hours = d.getHours(),
      min = d.getMinutes(),
      sec = d.getSeconds();

    if (hours < 10) hours = "0" + hours;
    if (min < 10) min = "0" + min;
    if (sec < 10) {
      sec = "0" + sec;
    }
    if (notHasSeconds) return [hours, min].join(":");
    else return [hours, min, sec].join(":");
  },
  getImage: (obj, demourl) => {
    let imageSrc = "";
    if (obj?.image?.image_url)
      imageSrc = helpers.getImageUrl(obj?.image?.image_url);
    else imageSrc = demourl;

    return imageSrc;
  },
  removeForwordSplash: (pathname) => {
    let text = pathname?.replace(/\//g, "");
    return text;
  },
  getImageProfile: (images) => {
    let imageUrl = null;
    let lastImageProf = null;
    images?.length &&
      images?.map((itm) => {
        if (itm?.image_type_id == 1) {
          lastImageProf = itm?.image_url;
        }
      });
    if (lastImageProf) imageUrl = lastImageProf;

    if (imageUrl) return process.env.REACT_APP_API_URL_IMAGE + imageUrl;

    return "";
  },
  getImageProfileID: (images) => {
    let id = null;

    images?.length &&
      images?.map((itm) => {
        if (itm?.image_type_id == 1) {
          id = itm?.id;
        }
      });
    return id;
  },
  getImageCover: (images) => {
    let imageUrl = null;
    let lastImageCover = null;
    images?.length &&
      images?.map((itm) => {
        if (itm?.image_type_id == 2) {
          lastImageCover = itm?.image_url;
        }
      });
    if (lastImageCover) imageUrl = lastImageCover;

    if (imageUrl) return process.env.REACT_APP_API_URL_IMAGE + imageUrl;

    return "";
  },
  copyCodeToClipboard: () => {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    let value = "";
    value = window.location.origin + `/guest-user-order`;
    dummy.value = value;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  },
  getCompanyName: (orderData, fromInvoice = false) => {
    var orderCompanyName = "";
    if (
      fromInvoice &&
      orderData?.invoice_number &&
      orderData?.invoices?.length
    ) {
      var invoice = orderData?.invoices[0];
      orderCompanyName = invoice?.company_name;
    } else {
      if (orderData?.order_guest && orderData?.order_guest?.company_name) {
        orderCompanyName = orderData?.order_guest?.company_name;
      } else if (
        orderData?.copied_from_user &&
        orderData?.copied_from_user?.company_name
      ) {
        orderCompanyName = orderData?.copied_from_user?.company_name;
      } else {
        orderCompanyName = orderData?.created_by?.company_name;
      }
    }
    return orderCompanyName;
  },
  getUserName: (orderData, fromInvoice = false) => {
    var orderUserName = "";
    if (
      fromInvoice &&
      orderData?.invoice_number &&
      orderData?.invoices?.length
    ) {
      var invoice = orderData?.invoices[0];
      orderUserName = invoice?.user_name;
    } else {
      if (orderData?.order_guest && orderData?.order_guest?.name) {
        orderUserName = orderData?.order_guest?.name;
      } else if (
        orderData?.copied_from_user &&
        orderData?.copied_from_user?.name
      ) {
        orderUserName = orderData?.copied_from_user?.name;
      } else {
        orderUserName = orderData?.created_by?.name;
      }
    }
    return orderUserName;
  },
  getPhone: (orderData, fromInvoice = false) => {
    var orderphone = "";
    if (
      fromInvoice &&
      orderData?.invoice_number &&
      orderData?.invoices?.length
    ) {
      var invoice = orderData?.invoices[0];
      orderphone = invoice?.phone;
    } else {
      if (orderData?.order_guest && orderData?.order_guest?.phone) {
        orderphone = orderData?.order_guest?.phone;
      } else if (
        orderData?.copied_from_user &&
        orderData?.copied_from_user?.phone
      ) {
        orderphone = orderData?.copied_from_user?.phone;
      } else {
        orderphone = orderData?.created_by?.phone;
      }
    }
    return orderphone;
  },
  getObject: (orderData, fromInvoice = false) => {
    var object = "";
    if (
      fromInvoice &&
      orderData?.invoice_number &&
      orderData?.invoices?.length
    ) {
      var invoice = orderData?.invoices[0];
      object = invoice;
    } else {
      if (orderData?.order_guest) {
        object = orderData?.order_guest;
      } else if (orderData?.copied_from_user) {
        object = orderData?.copied_from_user;
      } else {
        object = orderData?.created_by;
      }
    }
    return object;
  },
  isValidDateFormat: (dateString) => {
    // Regular expression to check if the date is in the correct format
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    // If the date string does not match the regex, it's not valid
    if (!regex.test(dateString)) {
      return false;
    }

    // Parse the date parts to integers
    const parts = dateString.split("-");
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript Date
    const day = parseInt(parts[2], 10);

    // Create a new Date object
    const date = new Date(year, month, day);

    // Check if the date object represents the correct date
    if (
      date.getFullYear() === year &&
      date.getMonth() === month &&
      date.getDate() === day
    ) {
      return true;
    } else {
      return false;
    }
  },
  formatDateNew: (date) => {
    if (!date) return;
    const year = date
      .getFullYear()
      .toString()
      .padStart(4, "0"); // Ensure 4 digits for year
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, add 1 and pad
    const day = date
      .getDate()
      .toString()
      .padStart(2, "0"); // Pad day with leading zero if needed

    return `${year}\\${month}\\${day}`;
  },
  handleDateChange: (e) => {
    const newDate = e; // Get the selected date string directly
    const date = new Date(newDate); // Convert the string to a Date object
    date.setFullYear(date.getFullYear() + 1); // Add one year to the date
    // const formattedDate = formatDate(date); // Format the new date
    return date;
  },
  hasNumbers: (str) => {
    return !/^[^\d'\u0660-\u0669,"=@,#+$!%^&*(){}\/<>\[\]]*$/.test(str);
  },
  hasSpecialCharacters(str) {
    return /[=,',",@,$,!,%,^,&,*,(,),{,},\/,<,>,\[]/.test(str);
  },
  formatPrice: (price) => {
    // Convert price to number format
    price = Number(price);

    // Format the price with commas
    return price.toLocaleString();
  },
  getReceiptData: (data, number, type,noValue) => {
    var name = "";
    if (type == 1) {
      //from order
      if (data?.invoices?.length) {
        let invoice = data?.invoices[0];
        if (invoice?.payments?.length) {
          let payment = invoice?.payments[0];
          if (number == 1)
            name = payment?.payment_number ? payment?.payment_number : null;
          else
            name = payment?.created_at
              ? helpers.formatDate(payment?.created_at)
              : "---";
        }
      }
      if (!name) {
        if (number == 1) {
          name = data?.receipt_number ? data?.receipt_number : "---";
        } else if (number == 2) {
          name = data?.receipt_date
            ? helpers.formatDate(data?.receipt_date)
            : "---";
        }
      }
    } else if (type == 2) {
      //from payment
      if (number == 1) {
        name = data?.payment_number ? data?.payment_number : "---";
      } else if (number == 2) {
        name = data?.created_at ? helpers.formatDate(data?.created_at) : "---";
      }
    } else if (type == 3) {
      //from vehicle
      if (data?.order?.invoices?.length) {
        let invoice = data?.order?.invoices[0];
        if (invoice?.payments?.length) {
          let payment = invoice?.payments[0];
          if (number == 1)
            name = payment?.payment_number ? payment?.payment_number : null;
          else
            name = payment?.created_at
              ? helpers.formatDate(payment?.created_at)
              : "---";
        }
      }
      if (!name) {
        if (number == 1) {
          name = data?.order?.receipt_number
            ? data?.order?.receipt_number
            : "---";
        } else if (number == 2) {
          name = data?.order?.receipt_date
            ? helpers.formatDate(data?.order?.receipt_date)
            : "---";
        }
      }
    } else if (type == 4) {
      //from invoice
      if (data?.payments?.length) {
        let payment = data?.payments[0];
        if (number == 1)
          name = payment?.payment_number ? payment?.payment_number : null;
        else
          name = payment?.created_at
            ? helpers.formatDate(payment?.created_at)
            : "---";
      }
      if (!name) {
        if (number == 1) {
          name = data?.order?.receipt_number
            ? data?.order?.receipt_number
            : "---";
        } else if (number == 2) {
          name = data?.order?.receipt_date
            ? helpers.formatDate(data?.order?.receipt_date)
            : "---";
        }
      }
    }
    if((!name||name=='---')&&noValue){
      return null;
    }
    return name;
  },
  getInvoiceData: (data, number, type,noValue) => {
    var name = "";
    if (type == 1) {
      // from order
      if (data?.invoices?.length) {
        let invoice = data?.invoices[0];
        if (invoice) {
          if (number == 1)
            name = invoice?.invoice_number ? invoice?.invoice_number : null;
          else
            name = invoice?.invoice_date
              ? helpers.formatDate(invoice?.invoice_date)
              : "---";
        }
      }
      if (!name) {
        if (number == 1) {
          name = data?.invoice_number ? data?.invoice_number : "---";
        } else if (number == 2) {
          name = data?.invoice_date
            ? helpers.formatDate(data?.invoice_date)
            : "---";
        }
      }
    } else if (type == 2) {
      // from payment
      if (data?.invoice) {
        let invoice = data?.invoice;
        if (invoice) {
          if (number == 1)
            name = invoice?.invoice_number ? invoice?.invoice_number : null;
          else
            name = invoice?.invoice_date
              ? helpers.formatDate(invoice?.invoice_date)
              : "---";
        }
      }
      name = "";
    } else if (type == 3) {
      // from vehicle
      if (data?.order) {
        var order = data?.order;
        if (order?.invoices?.length) {
          let invoice = order?.invoices[0];
          if (invoice) {
            if (number == 1)
              name = invoice?.invoice_number ? invoice?.invoice_number : null;
            else
              name = invoice?.invoice_date
                ? helpers.formatDate(invoice?.invoice_date)
                : "---";
          }
        }
        if (!name) {
          if (number == 1) {
            name = order?.invoice_number ? order?.invoice_number : "---";
          } else if (number == 2) {
            name = order?.invoice_date
              ? helpers.formatDate(order?.invoice_date)
              : "---";
          }
        }
      }
    } else if (type == 4) {
      //from invoice
      if ((number = 1)) name = data?.invoice_number;
      else
        name = data?.invoice_date
          ? helpers.formatDate(data?.invoice_date)
          : "---";
    }
    if((!name||name=='---')&&noValue){
      return null;
    }
    return name;
  },
  addDays: (date, days) => {
    if(!date) return null;
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  },
  copyReceiptNumber:(text)=>{
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);


    // Create a temporary textarea element
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.setAttribute("readonly", "");
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);

    // Copy the text to the clipboard
    textarea.select();
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard");
        toast.success('تم نسخ رمز التعريف بنجاح!')
      })
      .catch((err) => {
        console.error("Error copying text to clipboard: ", err);
        toast.warn('فشل في نسخ رمز التعريف!')
      });
    // Clean up
    document.body.removeChild(textarea);
  },
  concatGovermentName:(object)=>{
    console.log('sdfdsfdsfdsfdsfsddfsdfds====>',object)
    let name='';
    name=object?.name+(object?.caption?' - '+object?.caption:'');
    return name;
  }
};
export default helpers;
