import "./App.css";
import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import $ from "jquery";
import Cookies from "universal-cookie";
import SideMenu from "./SideMenu/SideMenu";
import { I18nextProvider } from "react-i18next";
import { CacheProvider } from "@emotion/react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import i18next from "i18next";
import Context from "./assets/js/context";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/test1.css";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { setUserToken } from "./reduxStore/UserReducer";
import { setscreenwidth } from "./reduxStore/SettingsReducer";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  Dashboard,
  Login,
  ProtectionAdmin,
  Preloading,
  Profile,
  User,
  Landing,
  Vehicles,
  Orders,
  Group,
  VehiclePlateType,
  TakeelIssueSource,
  MainNewOrder,
  MainCopyOrder,
  TransferType,
  Notification,
  GuestUserToken,
  EntityType,
  Activity,
  OrderSetting,
} from "./Pages/index";
import BackgroundGlAnimation from "./Components/BackgroundGlowingAnimation/BackgroundGlAnimation";

const cookies = new Cookies();

const MINUTE_MS = 60000;
function App() {
  const [state] = useState({
    data: [],
    ms: [],
    data1: [],
    Dash: [],
    check: "",
    che: "login",
    new: "",
    inprogress: "",
    closed: "",
    approved: "",
    rejected: "",
    archived: "",
    noti: "",
    desc: "",
    commenter: "",
    body: "",
    title: "",
    SiFilter: [],
  });
  const directions = useSelector((state) => state.settingsData.directions);
  const languageCode = useSelector((state) => state.settingsData.code);
  const mainPath = useSelector((state) => state.settingsData.mainpath);

  const Translate_ar = useSelector(
    (state) => state.translateData.translation_ar
  );
  const Translate_en = useSelector(
    (state) => state.translateData.translation_en
  );
  const maintheme = useSelector((state) => state.themeData.maintheme);
  const dispatch = useDispatch();
  const token = cookies.get("token");
  useEffect(() => {
    if (token) {
      dispatch(setUserToken(token));
    }
  }, [token]);

  const [theme, setTheme] = useState(
    createTheme({
      direction: directions, // Both here and <body dir="rtl">
      palette: {
        primary: {
          main: maintheme?.mainColor,
        },
        secondary: {
          main:maintheme?.mainColor+'A0',
        },
      },
    })
  );

  try {
    const bodyWeb = document.querySelector("body");
    if (bodyWeb) {
      bodyWeb.style.background = maintheme?.background;
    }
    i18next.init({
      interpolation: { escapeValue: false }, // React already does escaping
      lng: languageCode,
      resources: {
        ar: {
          common: Translate_ar,
        },
        en: {
          common: Translate_ar,
        },
      },
    });
  } catch (error) {
    console.log(error?.message);
  }

  useEffect(() => {
    localStorage.setItem("direction", directions);
    if (directions === "rtl") {
      document.dir = "rtl";
      const htmlTag = document.querySelector("html");
      if (htmlTag) {
        htmlTag.lang = "ar";
      }
      cookies.set("language", "ar");
    } else {
      // setCacheRtl(createCache({ key: 'css' }))
      document.dir = "ltr";
      cookies.set("language", "en");
    }

    setTheme(
      createTheme({
        direction: directions, // Both here and <body dir="rtl">
        palette: {
          primary: {
            main: maintheme?.mainColor,
          },
          secondary: {
            main:maintheme?.mainColor+'A0',
          },
        },
      })
    );
  }, [directions]);

  const isrtl = React.useMemo(() => {
    return createCache({
      key: directions === "rtl" ? "muirtl" : "muiltr",
      stylisPlugins: directions === "rtl" ? [rtlPlugin] : [],
    });
  }, [directions]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(setscreenwidth(window.innerWidth));
    });
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <img
        src={require("./assets/img/backgroundWhite.png")}
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          zIndex: -1,
          objectFit: "cover",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
      {/* <BackgroundGlAnimation/> */}
      <I18nextProvider i18n={i18next}>
        <CacheProvider value={isrtl}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <ToastContainer
                containerId="container_toast_id"
                position="top-center"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                transition={Slide}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <Context.Provider
                value={{
                  value: state,
                  action: {},
                }}
              >
                <Routes>
                  <Route path="/" element={<Login />} />
                  <Route path={mainPath} element={<SideMenu />}>
                    <Route
                      path={"dashboard"}
                      element={
                        <ProtectionAdmin>
                          <Dashboard />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path={"users"}
                      element={
                        <ProtectionAdmin>
                          <User />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path={"landing"}
                      element={
                        <ProtectionAdmin>
                          <Landing />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path={"user-profile"}
                      element={
                        <ProtectionAdmin>
                          <Profile />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path={"vehicles"}
                      element={
                        <ProtectionAdmin>
                          <Vehicles />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path={"new-order"}
                      element={
                        <ProtectionAdmin>
                          <MainNewOrder />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path={"orders"}
                      element={
                        <ProtectionAdmin>
                          <Orders />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path={"groups"}
                      element={
                        <ProtectionAdmin>
                          <Group />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path={"vehicle-plate-types"}
                      element={
                        <ProtectionAdmin>
                          <VehiclePlateType />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path={"takeel-issue-sources"}
                      element={
                        <ProtectionAdmin>
                          <TakeelIssueSource />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path={"copy-order"}
                      element={
                        <ProtectionAdmin>
                          <MainCopyOrder />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path={"edit-order"}
                      element={
                        <ProtectionAdmin>
                          <MainCopyOrder />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path={"transfer-types"}
                      element={
                        <ProtectionAdmin>
                          <TransferType />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path={"notifications"}
                      element={
                        <ProtectionAdmin>
                          <Notification />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path={"entity-types"}
                      element={
                        <ProtectionAdmin>
                          <EntityType />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path={"activities"}
                      element={
                        <ProtectionAdmin>
                          <Activity />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path={"order-settings"}
                      element={
                        <ProtectionAdmin>
                          <OrderSetting />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="*"
                      element={<ProtectionAdmin></ProtectionAdmin>}
                    />
                  </Route>
                  <Route
                    path={"/guest-user-order"}
                    element={<GuestUserToken />}
                  />
                  {/* <Route path='/redirect' element={<SessionEnd/>}/>
                  <Route path='/sorry' element={<SorryPage/>}/> */}
                  {/* <Route path="*" element={<ProtectionAdmin />} /> */}
                </Routes>
              </Context.Provider>
            </BrowserRouter>
          </ThemeProvider>
        </CacheProvider>
      </I18nextProvider>
    </Box>
  );
}

export default App;
