import LazyLoadingWrapping from './LazyLoadingWrapping'
export {default as Login} from './Login/Login'
export const Dashboard = LazyLoadingWrapping(() => import("./Dashboard/Dashboard"));
export const Profile = LazyLoadingWrapping(() => import("./Profile/Profile"));
export const Preloading = LazyLoadingWrapping(() => import("./Preload/Preloading"));
export const ProtectionAdmin = LazyLoadingWrapping(() => import("./ProtectionAdmin"));
export const User = LazyLoadingWrapping(() => import("./Users/User"));
export const Landing = LazyLoadingWrapping(() => import("./Landing"));
export const Vehicles = LazyLoadingWrapping(() => import("./Vehicles/Vehicles"));
export const Orders = LazyLoadingWrapping(() => import("./Orders/Orders"));
export const Group = LazyLoadingWrapping(() => import("./Group/Group"));
export const VehiclePlateType = LazyLoadingWrapping(() => import("./VehiclePlateTypes/VehiclePlateType"));
export const TakeelIssueSource = LazyLoadingWrapping(() => import("./TakeelIssueSources/TakeelIssueSource"));
export const MainCopyOrder = LazyLoadingWrapping(() => import("./CopyOrder/MainCopyOrder"));
export const TransferType = LazyLoadingWrapping(() => import("./TransferTypes/TransferType"));
export const Notification = LazyLoadingWrapping(() => import("./Notification/Notification"));
export const GuestUserToken = LazyLoadingWrapping(() => import("./GuestUsers/GuestUserToken"));
export const EntityType = LazyLoadingWrapping(() => import("./EntityType/EntityType"));
export const MainNewOrder = LazyLoadingWrapping(() => import("./NewOrder/MainNewOrder"));
export const Activity = LazyLoadingWrapping(() => import("./Activities/Activity"));
export const OrderSetting = LazyLoadingWrapping(() => import("./OrderSetting/OrderSetting"));























