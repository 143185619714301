import React, { useRef, useState } from "react";
import List from "@mui/material/List";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import TuneIcon from "@mui/icons-material/Tune";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import { useSelector, useDispatch } from "react-redux";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import { Box } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { NavLink, useLocation } from "react-router-dom";
import {
  setIsOpenSideMenu,
  setOpenCollapseOfOpen,
  setOpenChildOfOpen,
  setcurrentOpenMenuCollapse,
  setCurrentlyOpendMenuItem,
  setCopyOfcurrentOpenMenuItem,
} from "../reduxStore/SettingsReducer";
import { Collapse } from "react-collapse";
import * as RiIcons from "react-icons/ri";
import { useEffect } from "react";
import helpers from "../assets/js/helper";
import KeyboardArrowRightSharpIcon from "@mui/icons-material/KeyboardArrowRightSharp";
import KeyboardArrowLeftSharpIcon from "@mui/icons-material/KeyboardArrowLeftSharp";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function SideMenuItemArray({
  open,
  setOpen,
  pathsList,
  setPathList,
  MainContainerRef,

}) {
  const mainpath = useSelector((state) => state.settingsData.mainpath);

  const [t] = useTranslation("common");
  const location = useLocation();
  const listRef = useRef(null);
  const currentOpenMenuCollapse = useSelector(
    (state) => state.settingsData.currentOpenMenuCollapse
  );
  const currentlyOpenedMenuItem = useSelector(
    (state) => state.settingsData.currentlyOpenedMenuItem
  );
  const isopensidemenu = useSelector(
    (state) => state.settingsData.isopensidemenu
  );
  const sidemenutheme = useSelector((state) => state.themeData.sidemenutheme);
  const [openCollapse, setOpenCollapse] = useState(false);
  const openChildOfOpen = useSelector(
    (state) => state.settingsData.openChildOfOpen
  );
  const openCollapseOfOpen = useSelector(
    (state) => state.settingsData.openCollapseOfOpen
  );
  const rolesRedux = useSelector((state) => state.rolesData.roles);
  const globalTheme = useSelector((state) => state.themeData.globalTheme);
  const [openCollapse2, setOpenCollapse2] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const dispatch = useDispatch();
  const openCollapseToggle = (obj, event) => {
    console.log("isopensidemenu", obj);
    dispatch(setIsOpenSideMenu(true));
    setOpenCollapse(true);
    if (!isopensidemenu) {
      dispatch(setCurrentlyOpendMenuItem(obj));
      if (!obj?.show) {
        dispatch(setcurrentOpenMenuCollapse(obj?.id));
      }
    } else dispatch(setcurrentOpenMenuCollapse(obj?.id));
    dispatch(setOpenCollapseOfOpen(obj?.id));
    handleClick(event)
  };
  const openCollapseToggle2 = (obj) => {
    dispatch(setIsOpenSideMenu(true));
    setOpenCollapse2(!openCollapse2);
    setSelectedId(obj?.id);
    dispatch(setcurrentOpenMenuCollapse(obj?.id));
    dispatch(setOpenChildOfOpen(obj?.id));
  };

  useEffect(() => {
    console.log("OPENCOLLAPSED====>enter");
    if (currentOpenMenuCollapse && pathsList?.length) {
      console.log("OPENCOLLAPSED====>1");

      let data = handleOpenOrClose(currentOpenMenuCollapse, pathsList);
      if (data && data?.length > 0) {
        setPathList(data);
      }
    }
  }, [currentOpenMenuCollapse]);

  useEffect(
    () => {
      // Scroll to the selected menu item when it changes
      if (
        currentlyOpenedMenuItem &&
        currentlyOpenedMenuItem?.ref &&
        isopensidemenu &&
        MainContainerRef.current
      ) {
        console.log("asdasdasdasdasdasdasdasdasd====>1", location.pathname);

        setTimeout(() => {
          const container =
            MainContainerRef && MainContainerRef?.current
              ? MainContainerRef?.current
              : null;
          const selectedItemElement =
            currentlyOpenedMenuItem &&
            currentlyOpenedMenuItem?.ref &&
            currentlyOpenedMenuItem?.ref?.current
              ? currentlyOpenedMenuItem?.ref?.current
              : null;
          const scrollOffset =
            selectedItemElement && selectedItemElement?.offsetTop
              ? selectedItemElement?.offsetTop
              : null;
          if (container && scrollOffset != null)
            container.scrollTop = scrollOffset - 5;
        }, 50);
      } else if (!currentlyOpenedMenuItem && isopensidemenu) {
        console.log(
          "asdasdasdasdasdasdasdasdasd====>entered",
          location.pathname
        );

        pathsList &&
          pathsList?.map((item) => {
            if (item?.subLink && item?.subLink?.length) {
              console.log(
                "asdasdasdasdasdasdasdasdasd====>2",
                location.pathname
              );

              item?.subLink?.map((subItem) => {
                if (
                  `${mainpath}/${subItem?.to}` == location.pathname &&
                  subItem?.ref
                ) {
                  setTimeout(() => {
                    dispatch(setcurrentOpenMenuCollapse(subItem?.id));
                    dispatch(setCurrentlyOpendMenuItem(subItem));
                    // dispatch(setCopyOfcurrentOpenMenuItem(subItem));
                    const container =
                      MainContainerRef && MainContainerRef?.current
                        ? MainContainerRef?.current
                        : null;
                    const selectedItemElement =
                      subItem && subItem?.ref && subItem?.ref?.current
                        ? subItem?.ref?.current
                        : null;
                    const scrollOffset =
                      selectedItemElement && selectedItemElement?.offsetTop
                        ? selectedItemElement?.offsetTop
                        : null;
                    if (container && scrollOffset != null)
                      container.scrollTop = scrollOffset - 5;
                  }, 50);
                }
              });
            } else {
              console.log(
                "asdasdasdasdasdasdasdasdasd====>3",
                location.pathname
              );
              if (
                `${mainpath}/${item?.to}` == location.pathname &&
                item?.ref
              ) {
                setTimeout(() => {
                  dispatch(setcurrentOpenMenuCollapse(item?.id));
                  dispatch(setCurrentlyOpendMenuItem(item));
                  // dispatch(setCopyOfcurrentOpenMenuItem(item));
                  const container =
                    MainContainerRef && MainContainerRef?.current
                      ? MainContainerRef?.current
                      : null;
                  const selectedItemElement =
                    item && item?.ref && item?.ref?.current
                      ? item?.ref?.current
                      : null;
                  const scrollOffset =
                    selectedItemElement && selectedItemElement?.offsetTop
                      ? selectedItemElement?.offsetTop
                      : null;
                  if (container && scrollOffset != null)
                    container.scrollTop = scrollOffset - 5;
                }, 50);
              }
            }
          });
      }
    },
    !currentlyOpenedMenuItem
      ? [currentlyOpenedMenuItem, isopensidemenu, pathsList]
      : [currentlyOpenedMenuItem, isopensidemenu]
  );

  const renderShowMenuItem = (openParam) => {
    let ListArray = [];

    pathsList &&
      pathsList?.length > 0 &&
      pathsList?.map((obj, index) => {
        let check = false;

        if (obj && obj?.subLink && obj?.subLink?.length > 0) {
          //has sub
          obj?.roles(rolesRedux) == true &&
            ListArray.push(
              <Box
                key={obj?.name + "_main_" + obj?.id}
                sx={{
                  display: "flex",
                  justifyContent: !open ? "center" : "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  borderBottomLeftRadius: !obj?.show ? "5px !important" : "0",
                  borderBottomRightRadius: !obj?.show ? "5px !important" : "0",
                }}
                className="activeDropDown"
                onClick={() => openCollapseToggle(obj)}
                ref={obj?.ref}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "fit-content",
                    maxWidth: "55%",
                    padding: !open ? "10px 0 0 29px " : "0",
                  }}
                >
                  {open ? (
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 6 : "auto",
                        justifyContent: "center",
                        position: "relative",
                        left: open ? "20px" : "6.5px",
                      }}
                      className="itmdropdown"
                    >
                      {obj?.icon && obj?.icon(openParam)}
                    </ListItemIcon>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {obj?.icon && obj?.icon(openParam)}
                      <ListItemText
                        primary={obj?.name}
                        sx={{ opacity: !open ? 1 : 0 }}
                      />
                    </Box>
                  )}
                  <ListItemText
                    className="itmdropdown"
                    primary={obj?.name}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </Box>
                {!obj?.show ? (
                  <KeyboardArrowLeftSharpIcon
                    sx={{
                      fontSize: "30px",
                      color: globalTheme.mainColor,
                      width: "30%",
                    }}
                    className="itmdropdown"
                  />
                ) : (
                  <KeyboardArrowDownSharpIcon
                    size={19}
                    sx={{ fontSize: "22px", color: globalTheme.mainColor }}
                    className="itmdropdown"
                  />
                )}
              </Box>
            );

          obj?.roles(rolesRedux) == true &&
            ListArray.push(
              <Collapse
                key={obj?.name + "_collapse_" + obj?.id}
                isOpened={obj?.show && open}
                className={`activeDropDown mainCollapse ${
                  obj?.show && open ? "collapseOpen" : ""
                }`}
              >
                {obj?.subLink?.map((subItm) => {
                  // if(!check){

                  if (
                    subItm &&
                    subItm?.subLink &&
                    subItm?.subLink?.length > 0
                  ) {
                    //has sub and has sub
                    // here will be the condition of sub sub...

                    return (
                      <React.Fragment key={subItm?.name + "_" + subItm?.id}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            backgroundColor: "transparent !important",
                            cursor: "pointer",
                            marginBottom: "10px !important",
                          }}
                          className="activeDropDown activeDropDown2"
                          onClick={() => openCollapseToggle2(subItm)}
                          ref={subItm?.ref}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                marginLeft: "30px",
                                mr: open ? 6 : "auto",
                                justifyContent: "center",
                                position: "relative",
                                left: open ? "20px" : "6.5px",
                              }}
                              className="itmdropdown"
                            >
                              {subItm?.icon && subItm?.icon(openParam)}
                            </ListItemIcon>
                            <ListItemText
                              className="itmdropdown"
                              primary={subItm?.name}
                              sx={{ opacity: open ? 1 : 0 }}
                            />
                          </Box>
                          {!subItm?.show ? (
                            <RiIcons.RiArrowDropDownLine
                              style={{
                                fontSize: "30px",
                                color: globalTheme.mainColor,
                              }}
                              className="itmdropdown"
                            />
                          ) : (
                            <RiIcons.RiArrowDropUpLine
                              style={{
                                fontSize: "30px",
                                color: globalTheme.mainColor,
                              }}
                              className="itmdropdown"
                            />
                          )}
                        </Box>

                        <Collapse
                          isOpened={subItm?.show && open}
                          className={`activeDropDown ${
                            subItm?.show && open ? "collapseOpen" : ""
                          }`}
                        >
                          {subItm?.subLink?.map((subSubItem) => {
                            let checkIsInRole = false;
                            if (subSubItem?.roles(rolesRedux) == true) {
                              checkIsInRole = true;
                            }

                            if (checkIsInRole) {
                              // check=true;
                              return (
                                <NavLink
                                  id={`${helpers.removeForwordSplash(
                                    "admin" + subSubItem?.to
                                  )}`}
                                  to={subSubItem?.to}
                                  key={subSubItem?.name + "_" + subSubItem?.id}
                                  className="itmContainerdropdown itmdropdown"
                                  ref={subSubItem?.ref}
                                >
                                  <ListItem
                                    disablePadding
                                    sx={{ display: "block", mt: 0 }}
                                    disableRipple
                                    className="itmContainerdropdown2 itmdropdown pe-5"
                                  >
                                    <ListItemButton
                                      disableRipple
                                      sx={{
                                        minHeight: 48,
                                        justifyContent: open
                                          ? "initial"
                                          : "center",
                                        px: 2.5,
                                      }}
                                    >
                                      <ListItemIcon
                                        sx={{
                                          minWidth: 0,
                                          mr: open ? 3 : "auto",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {subSubItem?.icon &&
                                          subSubItem?.icon(openParam)}
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={subSubItem?.name}
                                        sx={{ opacity: open ? 1 : 0 }}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                </NavLink>
                              );
                            } else {
                              check = false;
                            }
                          })}
                        </Collapse>
                      </React.Fragment>
                    );
                  } else {
                    let checkIsInRole = false;

                    if (subItm?.roles(rolesRedux) == true) {
                      checkIsInRole = true;
                    }
                    if (checkIsInRole) {
                      check = true;
                    } else {
                      check = false;
                    }
                  }
                  // }
                  if (check)
                    return (
                      <NavLink
                        id={`${helpers.removeForwordSplash(
                          "admin" + subItm?.to
                        )}`}
                        to={subItm?.to}
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                        key={subItm?.name + "_" + subItm?.id}
                        className="itmContainerdropdown itmdropdown"
                        ref={subItm?.ref}
                      >
                        <ListItem
                          disablePadding
                          sx={{ display: "block", mt: 0 }}
                          disableRipple
                          className="itmContainerdropdown itmdropdown "
                        >
                          <ListItemButton
                            disableRipple
                            sx={{
                              minHeight: 48,
                              justifyContent: open ? "initial" : "center",
                              px: 2.5,
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                //  marginLeft:'30px',
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              {subItm?.icon && subItm?.icon(openParam)}
                            </ListItemIcon>
                            <ListItemText
                              primary={subItm?.name}
                              sx={{ opacity: open ? 1 : 0 }}
                            />
                          </ListItemButton>
                        </ListItem>
                      </NavLink>
                    );
                })}
              </Collapse>
            );
        } else {
          let checkIsInRole = false;
          if (obj?.roles(rolesRedux) == true) {
            checkIsInRole = true;
          }

          if (checkIsInRole) {
            check = true;
            ListArray.push(
              <NavLink
                to={obj?.to}
                key={obj?.name + "_" + obj?.id}
                style={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                ref={obj?.ref}
              >
                <ListItem disablePadding sx={{ display: "block", mt: 0 }}>
                  {open ? (
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.3,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {obj?.icon && obj?.icon(openParam)}
                      </ListItemIcon>
                      <ListItemText
                        primary={obj?.name}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  ) : (
                    <ListItemButton
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {obj?.icon && obj?.icon(openParam)}
                      <ListItemText
                        primary={
                          obj?.name && obj?.name?.length > 15
                            ? obj?.name?.slice(0, 15)
                            : obj?.name
                        }
                        sx={{ opacity: !open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  )}
                </ListItem>
              </NavLink>
            );
          } else {
            check = false;
          }
        }
      });
    return ListArray;
  };

  const handleOpenOrClose = (id, dataList) => {
    var arr = [];
    dataList &&
      dataList?.length > 0 &&
      dataList?.map((itm) => {
        if (itm?.subLink?.length > 0) {
          if (itm?.id == id) {
            arr.push({
              ...itm,
              show: itm?.show == true ? false : true,
            });
          } else {
            let object = itm;
            let subArr = [];
            itm?.subLink &&
              itm?.subLink?.length > 0 &&
              itm?.subLink?.map((itm2) => {
                if (itm2?.subLink?.length > 0) {
                  if (itm2?.id == id) {
                    subArr.push({
                      ...itm2,
                      show: itm2?.show == true ? false : true,
                    });
                  } else {
                    subArr.push(itm2);
                  }
                } else {
                  subArr.push(itm2);
                }
              });
            if (subArr?.length) {
              object = {
                ...itm,
                subLink: subArr,
              };
              arr.push(object);
            }
            //  handleOpenOrClose(id,itm?.subLink,itm?.id)
          }
        } else {
          arr.push(itm);
        }
      });

    setSelectedId(null);
    dispatch(setcurrentOpenMenuCollapse(null));
    return arr;
  };

  useEffect(() => {
    if (currentOpenMenuCollapse && pathsList?.length) {
      let data = handleOpenOrClose(currentOpenMenuCollapse, pathsList);
      if (data && data?.length > 0) {
        setPathList(data);
      }
    }
  }, [currentOpenMenuCollapse, openChildOfOpen, openCollapseOfOpen, open]);

  const handleClick = (event) => {
    if (event && event?.target) {
      const listItem = event?.target;

      if (listItem) {
        const offsetTop = listItem?.offsetTop;
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    }
  };
  return (
    <List
      sx={{
        height: "fit-content !important",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: open ? "center" : "center",
        justifyContent: "start",
        transition: "all 0.8s ease-in-out !important",
        "& .iconSideMenu": {
          color: globalTheme.mainColor,
          fontSize: "20px",
          position: "relative",
          top: open ? 0 : "5px",
        },
        "& .MuiListItemText-root": {
          flex: !open ? "none !important" : "1 1 auto",
          marginTop: !open ? "0 !important" : "4px",
          marginBottom: !open ? "0 !important" : "4px",
        },
        "& .MuiListItemText-root .MuiTypography-root": {
          textAlign: "left",
          font: !open
            ? "normal normal 10px/30px Cairo-Medium !important"
            : "normal normal 15px/30px Cairo-Medium !important",
          letterSpacing: "0px",
          color: sidemenutheme?.textitemcolor,
          opacity: "1",
          height: "fit-content !important",
        },
        "& .MuiButtonBase-root": {
          backgroundColor: "transparent !important",
          padding: "2px 20px !important",
        },
        "& .MuiListItem-root": {
          borderRadius: open ? "0px" : "50%",
          width: open ? "100%" : "75px",
          height: open ? "48px" : "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          flexDirection: open ? "row" : "column",
          borderRadius: "5px",
          overflow: "hidden",
          padding: "auto 4px !important",
          margin: "2.5px 0",
          transition: "width 0.1s ease-in !important",

          // background:location?.pathname==currentpath?`${sidemenutheme.itmeshover} !important`:`transparent !important`
        },
        "& .MuiListItem-root:hover": {
          backgroundColor: `${globalTheme.mainColor}30 !important`,
        },
        "& .MuiListItem-root:hover .iconSideMenu": {
          color: `${sidemenutheme?.secondaryColor} !important`,
        },
        "& .MuiListItem-root:hover .MuiListItemText-root .MuiTypography-root": {
          color: `${sidemenutheme?.secondaryColor} !important`,
        },
        "& .active .MuiListItem-root": {
          backgroundColor: `${globalTheme.mainColor}30 !important`,
        },
        "& .active .iconSideMenu": {
          color: `${sidemenutheme?.secondaryColor} !important`,
        },
        "& .active .MuiListItemText-root .MuiTypography-root": {
          color: sidemenutheme?.secondaryColor + " !important",
        },
        "& .activeDropDown": {
          backgroundColor: `#0e38571f !important`,
          width: open ? "90%" : "75px",
          height: open ? "48px" : "55px",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          borderBottomLeftRadius: !open ? "5px" : "0",
          borderBottomRightRadius: !open ? "5px" : "0",
          marginTop: "2.5px",
        },
        "& .activeDropDown .itmdropdown svg,& .activeDropDown .itmdropdown .MuiTypography-root,& .itmContainerdropdown .MuiTypography-root,& .itmContainerdropdown .iconSideMenu ": {
          color: sidemenutheme?.secondaryColor + " !important",
          cursor: "pointer !important",
        },
        "& .activeDropDown .itmdropdown": {
          width: "fit-content !important",
          maxWidth: "100%",
        },
        "& .itmContainerdropdown": {
          // backgroundColor: `${sidemenutheme?.dropdwoncolor} !important`,
          // width:'90% !important'
          marginBottom: "10px",
        },
        "& .ReactCollapse--collapse": {
          backgroundColor: `#0e38571f !important`,
          width: "90%",
          borderBottomLeftRadius: open ? "5px" : "0",
          borderBottomRightRadius: open ? "5px" : "0",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "2.5px",
        },
        "& .ReactCollapse--content": {
          marginBottom: "10px",
        },
        "& .itmContainerdropdown>.MuiListItem-root": {
          justifyContent: "flex-start !important",
          margin: "auto",
        },
        "& .MuiListItemButton-root": {
          width: "100%",
          boxShadow: "0 0 30px 10px yellow !important",
        },
      }}
      ref={listRef}
    >
      {renderShowMenuItem(open)}
    </List>
  );
}
