import React from "react";
import "./Preloading.css";
import { Box } from "@mui/material";
import minLogo from '../../assets/img/Smlogo.png'
import { useSelector } from "react-redux";
export default function Preloading() {
  const mainTheme = useSelector((state) => state.themeData.maintheme);

  return (
    <Box className="MainLoading">
      <Box className="opacityDashed"></Box>
      <Box
        sx={{
          width: "200px",
          height: "170px",
          backgroundColor: "#fff",
          borderRadius: "5px",
          boxShadow: "0 0 7px -2px #000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          '--mainColor':mainTheme?.mainColor,
          '--secondaryColor':mainTheme?.secondaryColor,
        }}
      >
        <svg className="snurra" width="150" height="150" viewBox="0 0 220 220">
          <defs>
            <linearGradient id="linjärGradient">
              <stop className="stopp1" offset="0" />
              <stop className="stopp2" offset="1" />
            </linearGradient>
            <linearGradient
              y2="160"
              x2="160"
              y1="40"
              x1="40"
              gradientUnits="userSpaceOnUse"
              id="gradient"
              xlinkHref="#linjärGradient"
            />
          </defs>
          <path
            className="halvan"
            d="m 164,100 c 0,-35.346224 -28.65378,-64 -64,-64 -35.346224,0 -64,28.653776 -64,64 0,35.34622 28.653776,64 64,64 35.34622,0 64,-26.21502 64,-64 0,-37.784981 -26.92058,-64 -64,-64 -37.079421,0 -65.267479,26.922736 -64,64 1.267479,37.07726 26.703171,65.05317 64,64 37.29683,-1.05317 64,-64 64,-64"
          />
          <circle className="strecken" cx="100" cy="100" r="64" />
        </svg>
        <img
          src={minLogo}
          style={{
            position: 'absolute',
            width:' 38px',
            height: '38px',
            objectFit:'contain'
          }}
        />
      </Box>
    </Box>
  );
}
