import { createSlice } from '@reduxjs/toolkit';
const directions__= localStorage.getItem('direction')? (localStorage.getItem('direction')==1?'rtl':'ltr'):'rtl'
var screenwidth= window.innerWidth
window.addEventListener('resize', ()=>{
   screenwidth=window.innerWidth
})
const GridMode=localStorage.getItem('gridMode')?localStorage.getItem('gridMode')=='true'?true:false:false;
const fetch_wialon_vehicle_data=localStorage.getItem('fetch_wialon_vehicle_data')?localStorage.getItem('fetch_wialon_vehicle_data')=='true'?true:false:false;


const initialState={
    code:'ar',
    offLine:false,
    directions:'rtl',
    screenwidth:screenwidth,
    drawerwidth:300,
    currentpath:'landing',
    globalLoading:false,
    polygons:[],
    isopensidemenu:false,
    openCollapseOfOpen:null,
    openChildOfOpen:null,
    currentOpenMenuCollapse:null,
    open_advance_search:false,
    open_advance_search_target:null,
    open_advance_search_transition:false,
    mainpath:'/OPDC-Takeel-Front',
    currentlyOpenedMenuItem:null,
    copyOfcurrentOpenMenuItem:null,
    fetch_wialon_vehicle_data:fetch_wialon_vehicle_data,


}
export const settingsSlice = createSlice({
  name: 'settingsData',
  initialState:initialState,
  reducers: {
    setcurrentOpenMenuCollapse:(state,action)=>{
      state.currentOpenMenuCollapse=action?.payload
    },
    setOpenChildOfOpen:(state,action)=>{
      state.openChildOfOpen=action.payload
    },
    setOpenCollapseOfOpen:(state,action)=>{
      state.openCollapseOfOpen=action.payload
    },
    setCode:(state,action)=>{
      state.code = action.payload
    },
    setOffLine:(state,action)=>{
     state.offLine=action.payload
    },
    setscreenwidth:(state,action)=>{
      state.screenwidth=action.payload
    },
    setDrawerWidth:(state,action)=>{
      state.drawerwidth=action.payload
    },
    setCurrentPath:(state,action)=>{
      state.currentpath=action.payload
    },
    setPolygonsRedux:(state,action)=>{
      state.polygons=action.payload
    },
    setIsOpenSideMenu:(state,action)=>{
      state.isopensidemenu=action.payload
    },
    setGlobalLoading:(state,action)=>{
      state.globalLoading=action.payload
    },
    setOpenAdvanceSearch:(state,action)=>{
      state.open_advance_search=action.payload
   },
    setOpenAdvanceSearchTarget:(state,action)=>{
       state.open_advance_search_target=action.payload
    },
    setOpenAdvanceSearchTransition:(state,action)=>{
      state.open_advance_search_transition=action.payload
   },
   setCurrentlyOpendMenuItem:(state,action)=>{
    state.currentlyOpenedMenuItem=action?.payload
  },
  setCopyOfcurrentOpenMenuItem:(state,action)=>{
    state.copyOfcurrentOpenMenuItem=action?.payload
  },
  setFetchWialonVehicleData:(state,action)=>{
    state.fetch_wialon_vehicle_data=action.payload
  }
  }
});

export const {
  setcurrentOpenMenuCollapse,
  setOpenCollapseOfOpen,
  setOpenChildOfOpen,
  setGlobalLoading,
  setOffLine,
  setscreenwidth,
  setCurrentPath,
  setPolygonsRedux,
  setIsOpenSideMenu,
  setCode,
  setOpenAdvanceSearch,
  setOpenAdvanceSearchTarget,
  setOpenAdvanceSearchTransition,
  setCurrentlyOpendMenuItem,
  setCopyOfcurrentOpenMenuItem,
  setFetchWialonVehicleData
} = settingsSlice.actions;



export default settingsSlice.reducer;
