import callAPI from "../ApiConfig";
import store from "../../app/store";

const login = async (props) => {
  return await callAPI({
    route: "login",
    method: "post",
    signal: props?.signal,
    data: props?.data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

const logout = async (props) => {
  const storeState = store.getState();
  var data = new FormData();
  return await callAPI({
    route: "logout",
    method: "post",
    signal: props?.signal,
    headers: {
      Authorization: `Bearer ${storeState?.userData?.token}`,
    },
  });
};

const users = async (props) => {
  const storeState = store.getState();

  return await callAPI({
    route: "users",
    method: "get",
    signal: props?.signal,
    params: props?.params,
    headers: {
      Authorization: `Bearer ${storeState?.userData?.token}`,
    },
  });
};

const addUser = async (props) => {
  const storeState = store.getState();

  return await callAPI({
    route: "users",
    method: "post",
    signal: props?.signal,
    data: props?.data,
    headers: {
      Authorization: `Bearer ${storeState?.userData?.token}`,
    },
  });
};
const editUser = async (props) => {
  const storeState = store.getState();

  return await callAPI({
    route: "users/" + props?.id,
    method: "put",
    signal: props?.signal,
    data: props?.data,
    headers: {
      Authorization: `Bearer ${storeState?.userData?.token}`,
    },
  });
};
const deleteUser = async (props) => {
  const storeState = store.getState();

  return await callAPI({
    route: "users/" + props?.id,
    method: "delete",
    signal: props?.signal,
    headers: {
      Authorization: `Bearer ${storeState?.userData?.token}`,
    },
  });
};
const getUser = async (props) => {
  const storeState = store.getState();

  return await callAPI({
    route: "user/" + props?.params?.id,
    method: "get",
    signal: props?.signal,
    headers: {
      Authorization: `Bearer ${storeState?.userData?.token}`,
    },
  });
};
const usersList = async (props) => {
  const storeState = store.getState();

  return await callAPI({
    route: "users_list",
    method: "get",
    signal: props?.signal,
    params: props?.params,
    headers: {
      Authorization: `Bearer ${storeState?.userData?.token}`,
    },
  });
};

const assignRolesToUser = async (props) => {
  const storeState = store.getState();

  return await callAPI({
    route: "assign_roles",
    method: "patch",
    signal: props?.signal,
    data: props?.data,
    params: props?.params,
    headers: {
      Authorization: `Bearer ${storeState?.userData?.token}`,
    },
  });
};

const userAPI = {
  login: login,
  users: users,
  addUser: addUser,
  editUser: editUser,
  deleteUser: deleteUser,
  getUser: getUser,
  usersList: usersList,
  logout: logout,
  assignRolesToUser: assignRolesToUser,
};
export default userAPI;
