import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  translation_en: {
    GLOBAL: {},
    LOGIN: {
      login_title: "Login",
    },
  },
  translation_ar: {
    GLOBAL: {
      cancel_button: "الغاء",
      save_button: "حفظ",
      GRID_NO_DATA: "لا توجد بيانات",
      GRID_NO_RESULT_DATA: "لا توجد بيانات",
      action_edit: "تعديل",
      action_view: "عرض",
      action_delete: "حذف",
      action_rating: "تقييم",
      SEARCH_RESULT_OF_SEARCH: "النتيجة",
      SEARCH_SEARCH_BUTTON: "بحث",
      SEARCH_CLEAR_BUTTON: "مسح",
      save: "حفظ",
      Save: "حفظ",
      close: "اغلاق",
      SEARCH_RESULT_OF_selected: "المحددة",
      alertPopup: "تحديث بيانات المستخدم",
      updateUserInfo: "تحديث",
      endSessionMessage:
        "تم انتهاء الجلسة الخاصة بك سيتم تحويلك لصفحة التسجل خلال",
      endSessionMessageUnits: "ثانية",
      sorryMessage:
        "عذرا لم نتمكن من تحويلك الى صفحة تسجيل الدخول، رجاءا قم  بتسجيل الدخول مره اخرى من النظام الرئيسي",
      pageNotFoundMessage: "عذرا الصفحة غير موجوده",
      clear: "مسح",
      actionEdit: "تعديل ",
      actionDelete: "حذف",
      actionView: "عرض",
      actions: "الوظائف",
      imageType: "نوع الصورة",
      update: "تحديث",
      openSearch: "عرض البحث",
      pageNumber: "الصفحة",
      result: "النتيجة",
      search: "بحث",
      export: "تصدير",
      create: "أنشاء",
      map: "خريطة",
      import: "استيراد",
      telegram: "تليكرام",
      view: "عرض",
      pageSize: "حجم الصفحة",
      no_data: "لاتوجد بيانات",
      select_column_to_hide: "عدد الأعمدة التي ترغب في عرضها",
      hide_or_show_columns: "إخفاء / إظهار الأعمدة",
      createdAt: "تاريخ الانشاء",
      createdBy: "المستخدم",
      fromDate: "من تاريخ",
      toDate: "الى تاريخ",
      AssingRoleAction: "أسناد صلاحيات",
      emptyPoPup: "لاتوجد بيانات",
      notHaveAnyRoles:'ليس لديك اي صلاحية',
      goHome:'الرجوع للصفحة الرئيسية',
      sorry:"عذرا!",
      reloadPage:"اعد تحميل الصفحة",
      pageNotFound:'الصفحة التي تبحث عنها غير متوفرة حاليا',
      youDontHavePermission:'ليس لديك إذن للوصول إلى هذه الصفحة',
      thereIsIssueWithServer:'هناك مشكلة في الخادم'
    },
    LOGIN: {
      login_title: "تسجيل دخول",
      company_name: "نظام Milestone",
      company_title: "لادارة المجمعات السكنية والعقارات",
      wellcome_message: "مرحبا بك في نظام Milestone",
      Introductory_message:
        "هذا نظام آمن وستحتاج إلى تقديم تفاصيل تسجيل الدخول الخاصة بك للوصول إلى الموقع.",
      user_name: "اسم المستخدم",
      user_password: "كلمة المرور",
      checkbox_remember_me: "تذكرني",
      login: "تسجيل دخول",
      forget_password: "هل نسية كلمة المرور؟",
      footer_message: "مقدم من شركة ركن الحلول المتكاملة لتقنية المعلومات",
      reset_password_title: "أكتب رقم الهاتف الخاص بك لاستعادة كلمة المرور",
      phone_number: "رقم الهاتف",
      Submit: "أرسال",
      recived_sms_code_title: "أدخل الرمز المرسل اليك برسالة قصيرة",
      not_recivied_message: "لم يصلك الرمز ؟",
      reSend_message: "أعادة ارسال",
      new_password_form_title: "يرجى كتابة كلمة المرور الجديدة الان",
      confirm_user_password: "تأكيد كلمة المرور",
      save_changes_passwords: "حفظ التغييرات",
    },
    ERROR: {
      login_username_error: "اسم المستخدم مطلوب",
      login_userpassword_error: "يجب ادخال كلمة المرور",
      userName_or_password_error: "اسم المستخدم او كلمة المرور غير صحيحة",
    },
    SIDEMENU: {
      dashboard: "لوحة التحكم",
      profiel: "الملف الشخصي",
      logout: "تسجيل خروج",
      sections: "الاقسام",
      sectionTypes: "انواع الاقسام",
      imageTypes: "انواع الاصور",
      masters: "الرئيسية",
      sectionPost: "المنشورات",
      users: "المستخدمين",
      vehicles: "ألاليات",
      orders: "الطلبات السابقة",
    },
    DASHBOARD: {
      request_wait: "الطلبات  في الانتظار",
      request_canceled: "الطلبات الملغاة",
      request_completed: "الطلبات المكتملة",
      request_sum: "جميع الطلبات",
      unit_counts: "عدد الوحدات",
      title: "لوحة التحكم",
      request_new: "الطلبات الجديدة",
      show_status_title: "مخطط نسب الحالات للطلبات",
      show_RatedScore_title: "مخطط التقييم للطلبات",
    },
    ADVANCESEARCH: {
      title: "البحث عن عملاء",
      customer_name: "أسم العميل",
      customer_phone_number: "رقم الهاتف",
      Name_ofCumpany: "تاريخ التسجيل",
      fromDate: "من ",
      toDate: "الى",
      no_cutomerTypeData: "لا توجد بيانات",
      SelectCustomerWorkType: "نوع العميل",
      salesEmployee: "موظف المبيعات",
      selectedReason: "الغرض",
      no_resones: "لا توجد بيانات",
      advancesearch: "البحث المتقدم",
      applaySearch: "تطبيق البحث",
      sideOfWork: "جهة العمل",
      no_workside: "لا توجد بيانات",
      companyname: "اسم الشركة او الجهة او الشخص",
    },
    NETWORKMESSAGE: {
      addCustomerMessageSuccess: "تم اضافة المعلومات بنجاح",
      updatedDataSuccess: "تم تحديث المعلومات بنجاح",
      addCustomerMessageError: "خطء غير معروف",
      deleteErrorr: "خطء غير معروف",
      deleteSuccess: "تم الحذف بنجاح",
      messageError: "خطء غير معروف",
      insertSuccess: "تم اضافة البيانات بنجاح",
      canceldSuccess:'تم الالغاء بنجاح'
    },
    TOOLTIP: {
      EXPORT: "تصدير",
      IMPORT: "استيراد",
      CREATE: "انشاء",
      MAP: "خريطة",
      TELEGRAM: "تليكرام",
    },
    PROFILE: {
      email: "البريد الالكتروني",
      USER_TITLE: "معلومات المستخدم",
      FULL_NAME: "أسم المستخدم كامل",
      USERNAME: "اسم المستخدم",
      PHONE: "رقم الهاتف",
      UPDATE_BUTTON: "تحديث البيانات",
    },
    APPBAR: {
      settings: "الاعدادت",
      profile: "الملف الشخصي",
      logout: "تسجيل الخروج",
    },
    ALERTS: {
      deleteTitle: "هل أنت متأكد؟",
      deleteBody: "بمجرد حذفه ، لن تتمكن من استعادته!",
      seleted_success: "تم الحذف بنجاح",
    },
    IMAGETYPES: {
      title: "أنواع الصور",
      id: "#",
      name: "الاسم",
      description: "الوصف",
      createdBy: "المستخدم",
      titleCreate: "أضافة نوع صورة",
      titleEdit: "تعديل بيانات نوع صورة",
      addDescription: "الوصف",
      // section:'القسم',
      addName: "الاسم",
      searchName: "اسم نوع صورة",
    },
    IMAGES: {
      imageDetailsDescription: "وصف الصورة",
      imageDetailsName: "اسم الصورة",
      imageDetailsTitle: "تفاصيل الصورة",
    },
    VALIDATIONMESSAGES: {
      sectionName: "يجب ادخال اسم القسم",
      sectionId: "يرجى اختيار القسم",
      sectionTypeName: "اسم نوع القسم مطلوب",
      sectionPostId: "يرجى اختيار المنشور",
      sectionPostTitle: "عنوان المنشور مطلوب",
      sectionPostSectionType: "نوع القسم مطلوب",
      userName: "الاسم مطلوب",
      userConPassword: "تأكيد كلمة المرور مطلوبة",
      userPassword: "كلمة المرور مطلوبة",
      userEmailFormat: "صيفة البريد الألكتروني غير صحيحة",
      userEmail: "البريد الالكتروني مطلوب",
      userUserName: "اسم المستخدم مطلوب",
      userUserNameInvalid: "صيغة اسم المستخدم غير صحيح",
      userNameIsNotEnglish: "اسم المستخدم يجب اني يكون بالغة الأنكليزية",
      userConfirmPassNotEqualToPass: "رمز التأكيد غير مطابق للكلمة المرور",
      userId: "المستخدم مطلوب",
    },
    FILES: {
      title: "تفاصيل الملف",
      name: "الاسم",
      description: "ملاحظة",
      fileType: "نوع الملف",
    },
    USERS: {
      title: "المستخدمين",
      id: "#",
      name: "اسم المخول",
      description: "الوصف",
      createdBy: "المستخدم",
      titleCreate: "أضافة مستخدم",
      titleEdit: "تعديل بيانات المستخدم",
      addDescription: "الوصف",
      addName: "اسم المخول",
      addUserName: "اسم المستخدم",
      addEmail: "البريد الالكتروني",
      searchName: "اسم المخول",
      image: "الصور",
      username: "اسم المستخدم",
      email: "البريد الالكتروني",
      active: "الحالة",
      activeUser: "فعال",
      unActiveUser: "غير فعال",
      password: "كلمة المرور",
      con_password: "تأكيد كلمة المرور",
      active: "فعال",
      unactive: "غير فعال",
      searchUnactive: "غير فعال",
      searchActive: "فعال",
      all: "الكل",
      status: "الحالة",
      searchUserName: "اسم المستخدم",
      searchEmail: "البريد الالكتروني",
      assignRole: "أسناد صلاحيات للمستخدم",
      roleName: "اسم الصلاحية",
      selectedRole: "الصلاحيات المحدد",
      UnselectedRole: "الصلاحيات الغير محددة",
    },
    ROLES: {
      name: "الاسم",
    },
    VEHICLES: {
      id: "#",
      VehicleOwnerName: "بيانات مالك الالية",
      DriverFirstName: "اسم السائق",
      DriverSecondName: "اسم اب السائق",
      DriverThirdName: "اسم جد السائق",
      DriverIDNumber: "رقم الهوية أو البطاقة الموحدة او الجواز",
      DriverIDType: "وثيقة اثبات الشخصية",
      VehicleModel: "طراز المركبة",
      VehicleType: "صنف التسجيل",
      VehiclePlateType: "نوع اللوحة",
      VehiclePlateGovernant: "المحافظة",
      VehiclePlateLetter: "الحرف",
      VehiclePlateNumberOnly: "رقم اللوحة بدون حروف",
      VehicleIDNumber: "رقم السنوية",
      VehicleIDIssueDate: "تاريخ الإصدار",
      VehicleIDExpiryDate: "تاريخ النفاذ",
      VinNumber: "رقم شاصي المركبة (القاطرة)",
      TrailerVinNumber: "رقم شاصي العربة ( المقطورة )",
      TakeelNumber: "الرقم التسلسلي لشهادة التكييل",
      TakeelIssueSource: "جهة الإصدار",
      TakeelIssueDate: "تاريخ إصدار التكيل",
      TakeelExpiryDate: "تاريخ الانتهاء",
      InsertDateTime: "تاريخ ادخال المركبة",
      LastUpdateDatetime: "تاريخ  اخر تحديث  للمركبة",
      User: "المستخدم",
      companyName: "اسم الشركة أو الجهة او الشخص ",
      vehiclesCount: "عدد المركبات",
      createVehicle: "إضافة مركبة",
      editVehicleVehicle: "تعديل مركبة",
    },
  },
};
export const TranslateSlice = createSlice({
  name: "translateData",
  initialState: initialState,
  reducers: {
    setTranslateEnglish: (state, action) => {
      state.translation_en = action.payload;
    },
    setTranslateArabic: (state, action) => {
      state.translation_ar = action.payload;
    },
  },
});

export const {
  setTranslateEnglish,
  setTranslateArabic,
} = TranslateSlice.actions;

// export const sideMenuMode = state => state.settingsData.sideMenuMode;

export default TranslateSlice.reducer;
