import { useState, useEffect, Fragment } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationCard from "./NotificationCard";
import { Badge, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  setNotificationsRedux,
  setTotalUnreadNotificationsCountRedux,
} from "../../reduxStore/NotificationReducer";
import axios from "axios";
import Cookies from "universal-cookie";
import notificationAPI from "../../Network/Notification";
// import NotificationsIcon from "@mui/icons-material/Notifications";

const cookies = new Cookies();

export default function NotificationMenu() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [notificationIdsLoaded, setNotificationIdsLoaded] = useState([]);
  const mainpath = useSelector((state) => state.settingsData.mainpath);
  const unReadCount = useSelector(state => state.notification.total_unread);
  const [t] = useTranslation("common");
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const loadNotificationsData = async () => {
    try {
      setLoading(true);
      let result = await notificationAPI.unReadedNotifications({
        noLoading: true,
      })
      setLoading(false);
      if (result?.status) {
        dispatch(setNotificationsRedux(result?.data?.data?.unreaded_Notifications));
        setNotificationIdsLoaded(result?.data?.data?.unreaded_Notifications);
        let ids=result?.data?.data?.unreaded_Notifications?.map(item=>item?.id)
        setNotificationsAsRead(ids)
      } else {
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) loadNotificationsData();
    return () => { };
  }, [anchorEl]);

  const setNotificationsAsRead = async (ids) => {
   if(!ids ||ids?.length<=0){
    return;
   }
    try {
      setLoading(true);
      let result = await notificationAPI.setNotificationsAsRead({
        noLoading: true,
        data:{
          ids:ids
        }
      })
      setLoading(false);
      if (result?.status) {
        loadNotificationsCount()
      } else {
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const loadNotificationsCount= async () => {
    try {
      // setLoading(true);
      let result = await notificationAPI.unReadedNotifications({
        noLoading: true,
        params:{
          get_count:1,
        }
      })
      // setLoading(false);
      if (result?.status) {
        dispatch(setTotalUnreadNotificationsCountRedux(result?.data?.data?.un_readed_count));
        
      } else {
      }
    } catch (err) {
      setLoading(false);
    }
  };


  return (
    <Fragment>
      <Box
        sx={{
          // backgroundColor: "rgb(223,226,231)",
          padding: "10px",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          margin: "0 4px",
          // overflow: "hidden",
          cursor: "pointer",
          "&:hover": {
            transform: "scale(1.1)",
            backgroundColor: "rgb(223,226,231)",
          },
        }}
        id="basic-button"
        onClick={handleClick}
        size="small"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        color="inherit"
      >
        {unReadCount > 0 ? <span
          style={{
            position: "absolute",
            width: unReadCount>100?"33px":"19px",
            height: "19px",
            backgroundColor: "tomato",
            borderRadius:  unReadCount>100?"15px":"50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            right: unReadCount>100?'-7px':"4px",
            fontSize: "12px",
            fontFamily: "Cairo-Bold",
          }}
        >
          {unReadCount>100?'100+':unReadCount}
        </span> : null}
        <NotificationsIcon
          color="rgb(99,115,129)"
          sx={{ color: "rgb(99,115,129)" }}
        />
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 110,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {loading ? (
          <MenuItem key={"notification_loading"}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",

                width: "300px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            </Box>
          </MenuItem>
        ) : notificationIdsLoaded?.length ? (
          <>
            {notificationIdsLoaded?.map((notification, index) => {
              return (
                <MenuItem key={"notification_" + index} onClick={handleClose}>
                  <NotificationCard
                    {...notification}
                    mainpath={mainpath}
                    onClick={() => {
                      // onNotificationForward(notification);
                    }}
                  />
                </MenuItem>
              );
            })}

            <Button
              variant="contained"
              // spacing={2}
              sx={{
                // flexGrow: 1,

                width: "100%",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                  color: "#126A99A0",
                  boxShadow: "none",
                },
                backgroundColor: "#FFFFFF",
                color: "#126A99",
                boxShadow: "none",
                height: "35px",
                fontFamily: "Cairo-Bold",
              }}
              onClick={() => {
                navigate(mainpath + "/notifications");
              }}
            >
              Show More
            </Button>
          </>
        ) : (
          <MenuItem key={"notification_"} onClick={handleClose}>
            <NotificationCard />
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  );
}
