import React, { useState, useEffect, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import { Drawer, AppBar } from "./SideMenuTheme";
import { useSelector, useDispatch } from "react-redux";
import SideMenuItemArray from "./SideMenuItemArray";
import { useTranslation } from "react-i18next";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation } from "react-router-dom";

import DashboardIcon from "@mui/icons-material/Dashboard";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { toast } from "react-toastify";
import { Outlet } from "react-router-dom";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import userImage from "../assets/img/user.png";
import { setCurrentlyOpendMenuItem, setIsOpenSideMenu } from "../reduxStore/SettingsReducer";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import userAPI from "../Network/User";
import { IconButton } from "@mui/material";
import AppBarItems from "./Components/AppBarItems";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import animationLogo from "../assets/img/logo.png";
import userTemImage from "../assets/img/user.png";
import SmaleLogoImage from "../assets/img/Smlogo.png";
import Preloading from "../Pages/Preload/Preloading";
import { setRolesRedux } from "../reduxStore/RolesReducer";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SegmentIcon from "@mui/icons-material/Segment";
import TypeSpecimenIcon from "@mui/icons-material/TypeSpecimen";
import GroupIcon from "@mui/icons-material/Group";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ReceiptIcon from '@mui/icons-material/Receipt';
import AddIcon from '@mui/icons-material/Add';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SettingsIcon from '@mui/icons-material/Settings';
import TuneIcon from '@mui/icons-material/Tune';
import BadgeIcon from '@mui/icons-material/Badge';
import CategoryIcon from '@mui/icons-material/Category';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import EngineeringIcon from '@mui/icons-material/Engineering';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import PaymentsIcon from '@mui/icons-material/Payments';
import PaymentIcon from '@mui/icons-material/Payment';
import BookIcon from '@mui/icons-material/Book';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert';
import AnlyticsIcon from '@mui/icons-material/Analytics'
import LayersIcon from '@mui/icons-material/Layers';

const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL}`,
};
const cookies = new Cookies();
export default function SideMenu() {
  const [t] = useTranslation("common");
  const location = useLocation();
  const drawerwidth = useSelector((state) => state.settingsData.drawerwidth);
  const sidemenutheme = useSelector((state) => state.themeData.sidemenutheme);
  const appbarTheme = useSelector((state) => state.themeData.appbar);
  const maintheme = useSelector((state) => state.themeData.appbar);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const mainpath = useSelector((state) => state.settingsData.mainpath);
  const directions = useSelector((state) => state.settingsData.directions);
  const rolesRedux = useSelector((state) => state.rolesData.roles);
  const globalTheme = useSelector((state) => state.themeData.globalTheme);
  const userData = useSelector((state) => state.userData.data);
  const MainContainerRef = useRef(null);
  const copyOfcurrentOpenMenuItem = useSelector(
    (state) => state.settingsData.copyOfcurrentOpenMenuItem
  );
  const isopensidemenu = useSelector(
    (state) => state.settingsData.isopensidemenu
  );
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = React.useState();
  const globalLoading = useSelector(
    (state) => state.settingsData.globalLoading
  );
  var checkName = "";
  const getAppBarTitle = (data) => {
    data &&
      data?.length > 0 &&
      data?.map((itm) => {
        if (itm?.subLink?.length > 0) {
          getAppBarTitle(itm?.subLink);
        } else {
          if (mainpath + "/" + itm?.to == window.location.pathname) {
            console.log(
              "pathname",
              window.location.pathname,
              "...",
              mainpath + "/" + itm?.to
            );
            checkName = itm?.name;
          }
        }
      });
    console.log("nameroute", checkName);
    return checkName;
    // setAppBarTitle(checkName)
  };
  const navigate = useNavigate();
  const checkRolesForMasterCollapse = (rolesRedux) => {
    let check = false;
    if (
      (rolesRedux?.view_vehicle_plate_type?.value)||
      (rolesRedux?.view_group?.value ) ||
      (rolesRedux?.view_transfer_type?.value ) ||
      (rolesRedux?.view_takeel_issue_source?.value)||
      (rolesRedux?.show_entity_type?.value )
    ) {
      check = true;
    }
    return check;
  };
  const checkRolesForSettingCollapse = (rolesRedux) => {
    let check = false;
    if (
      rolesRedux?.show_log?.value||
      rolesRedux?.add_or_edit_order_setting?.value
    ) {
      check = true;
    }
    return check;
  };
  useEffect(() => {
    console.log("isopensidemenu", isopensidemenu);
    setOpen(isopensidemenu);
  }, [isopensidemenu]);
  const [pathsList, setPathList] = useState([
    {
      id: 1,
      roles: (rolesRedux) => rolesRedux?.dashboard?.value,
      name: t("SIDEMENU.dashboard"),
      to: "dashboard",
      icon: (open) => (
        <Tooltip
          arrow
          placement="right"
          title={
            !open ? (
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("SIDEMENU.dashboard")}
              </span>
            ) : (
              ""
            )
          }
        >
          <DashboardIcon className="iconSideMenu" />
        </Tooltip>
      ),
      subLink: [],
      ref:useRef(null),
    },
    {
      id: 2,
      roles: (rolesRedux) => rolesRedux?.view_user?.value,
      name: t("SIDEMENU.users"),
      to: "users",
      icon: (open) => (
        <Tooltip
          arrow
          placement="right"
          title={
            !open ? (
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("SIDEMENU.users")}
              </span>
            ) : (
              ""
            )
          }
        >
          <PeopleAltIcon className="iconSideMenu" />
        </Tooltip>
      ),
      subLink: [],
      ref:useRef(null),

    },
    {
      id: 3,
      roles: (rolesRedux) => rolesRedux?.add_order?.value,
      name: "طلب جديد",
      to: "new-order",
      icon: (open) => (
        <Tooltip
          arrow
          placement="right"
          title={
            !open ? (
              <span style={{ fontFamily: "Cairo-Medium" }}>طلب جديد</span>
            ) : (
              ""
            )
          }
        >
          <LocalShippingIcon className="iconSideMenu" />
        </Tooltip>
      ),
      subLink: [],
      ref:useRef(null),

    },
    {
      id: 3,
      roles: (rolesRedux) => rolesRedux?.view_vehicle?.value,
      name: t("SIDEMENU.vehicles"),
      to: "vehicles",
      icon: (open) => (
        <Tooltip
          arrow
          placement="right"
          title={
            !open ? (
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("SIDEMENU.vehicles")}
              </span>
            ) : (
              ""
            )
          }
        >
          <LocalShippingIcon className="iconSideMenu" />
        </Tooltip>
      ),
      subLink: [],
      ref:useRef(null),
    },
    {
      id: 4,
      roles: (rolesRedux) => rolesRedux?.view_order?.value,
      name: t("SIDEMENU.orders"),
      to: "orders",
      icon: (open) => (
        <Tooltip
          arrow
          placement="right"
          title={
            !open ? (
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("SIDEMENU.orders")}
              </span>
            ) : (
              ""
            )
          }
        >
          <ShoppingBasketIcon className="iconSideMenu" />
        </Tooltip>
      ),
      subLink: [],
      ref:useRef(null),
    },
    {
      id: 17,
      roles: (rolesRedux) => rolesRedux?.show_notification?.value,
      name: "الاشعارات",
      to: "notifications",
      icon: (open) => (
        <Tooltip
          arrow
          placement="right"
          title={
            !open ? (
              <span style={{ fontFamily: "Cairo-Medium" }}>{"الاشعارات"}</span>
            ) : (
              ""
            )
          }
        >
          <NotificationsActiveIcon className="iconSideMenu" />
        </Tooltip>
      ),
      subLink: [],
      ref:useRef(null),
    },
    {
      id: 20001,
      roles: (rolesRedux) => checkRolesForMasterCollapse(rolesRedux),
      name: t("SIDEMENU.masters"),
      to: "",
      icon: (open) => (
        <Tooltip
          arrow
          placement="right"
          title={
            !open ? (
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("SIDEMENU.masters")}
              </span>
            ) : (
              ""
            )
          }
        >
          <SegmentIcon className="iconSideMenu" />
        </Tooltip>
      ),
      subLink: [
        {
          id: 7,
          roles: (rolesRedux) => rolesRedux?.view_group?.value,
          name: "المجاميع",
          to: "groups",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>المجاميع</span>
                ) : (
                  ""
                )
              }
            >
              <GroupIcon className="iconSideMenu" />
            </Tooltip>
          ),
          subLink: [],
          ref:useRef(null),
        },
        {
          id: 8,
          roles: (rolesRedux) => rolesRedux?.view_vehicle_plate_type?.value,
          name: "انواع لوحات المركبات",
          to: "vehicle-plate-types",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    انواع لوحات المركبات
                  </span>
                ) : (
                  ""
                )
              }
            >
              <TypeSpecimenIcon className="iconSideMenu" />
            </Tooltip>
          ),
          subLink: [],
          ref:useRef(null),
        },
        {
          id: 13,
          roles: (rolesRedux) => rolesRedux?.view_takeel_issue_source?.value,
          name: "مصادر جهة التكييل",
          to: "takeel-issue-sources",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    مصادر جهة التكييل
                  </span>
                ) : (
                  ""
                )
              }
            >
              <AddModeratorIcon className="iconSideMenu" />
            </Tooltip>
          ),
          subLink: [],
          ref:useRef(null),
        },
        {
          id: 16,
          roles: (rolesRedux) => rolesRedux?.view_transfer_type?.value,
          name: "نوع المركبات",
          to: "transfer-types",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    انواع المركبات
                  </span>
                ) : (
                  ""
                )
              }
            >
              <MoveUpIcon className="iconSideMenu" />
            </Tooltip>
          ),
          subLink: [],
          ref:useRef(null),
        },
        {
          id: 22,
          roles: (rolesRedux) => rolesRedux?.show_entity_type?.value,
          name: "أنواع الجهات",
          to: "entity-types",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    انواع الجهات
                  </span>
                ) : (
                  ""
                )
              }
            >
              <BadgeIcon className="iconSideMenu" />
            </Tooltip>
          ),
          subLink: [],
          ref:useRef(null),
        },
      ],
      ref:useRef(null),
    },
    {
      id: 20003,
      roles: (rolesRedux) => checkRolesForSettingCollapse(rolesRedux),
      name: 'الاعدادات',
      to: "",
      icon: (open) => (
        <Tooltip
          arrow
          placement="right"
          title={
            !open ? (
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {'الاعدادات'}
              </span>
            ) : (
              ""
            )
          }
        >
          <SettingsIcon className="iconSideMenu" />
        </Tooltip>
      ),
      subLink: [
        {
          id: 29,
          roles: (rolesRedux) => rolesRedux?.add_or_edit_order_setting?.value,
          name: "اعدادات الطلب",
          to: "order-settings",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>{"اعدادات الطلب"}</span>
                ) : (
                  ""
                )
              }
            >
              <TuneIcon className="iconSideMenu" />
            </Tooltip>
          ),
          subLink: [],
          ref:useRef(null),
        },
        {
          id: 28,
          roles: (rolesRedux) => rolesRedux?.show_log?.value,
          name: "سجل النشاطات",
          to: "activities",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>سجل النشاطات</span>
                ) : (
                  ""
                )
              }
            >
              <LayersIcon className="iconSideMenu" />
            </Tooltip>
          ),
          subLink: [],
          ref:useRef(null),
        },
      ],
      ref:useRef(null),
    },
  ]);

  const renderComponent = () => {
    let check = false;
    pathsList &&
      pathsList?.length > 0 &&
      pathsList?.map((itm) => {
        if (location?.pathname == itm?.to) {
          return itm?.component;
        }
      });
  };
  const getUserImage = () => {
    return userInfo?.hasImage ? userInfo.img_url : userImage;
  };

  useEffect(() => {
    if (rolesRedux) setPathList(pathsList);
  }, [rolesRedux]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openSearchBox = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const hadleCheckIdExists = (roles) => {
    const updatedRoles = Object.entries(rolesRedux).reduce(
      (acc, [key, value]) => {
        acc[key] = { ...value, value: false };
        return acc;
      },
      {}
    );
    dispatch(setRolesRedux(updatedRoles));
    console.log("sadasdasdasdasd", updatedRoles);
  };
  const logout = async () => {
    try {
      let data = await userAPI.logout();
      if (data && data?.status) {
        if (cookies.get("token")) cookies.remove("token", { path: "/" });
        if (cookies.get("language", { path: "/" }))
          cookies.remove("language", { path: "/" });
        if (cookies.get("default_route", { path: "/" }))
          cookies.remove("default_route", { path: "/" });
        // localStorage.clear();
        hadleCheckIdExists();
        navigate("/");
      }
    } catch (err) {
      toast.warn(t("NETWORKMESSAGE.messageError"));
    }
  };
  const handleOpenClosedMenu = () => {
    if (pathsList && pathsList.length) {
      console.log("asdasdasdsadasdsad", window.location.pathname);
      var arr = [],
        checkExsts = false;
      pathsList?.map((item) => {
        if (item?.subLink?.length > 0) {
          let find = item?.subLink?.find((subItem) => {
            console.log("asdasdasdsadasdsad", (mainpath+'/'+ subItem?.to) + "---" + window.location.pathname);
            if (mainpath+'/' + subItem?.to === window.location.pathname) {
              return true;
            } else return false;
          });
          if (find) {
            checkExsts = true;
            arr.push({
              ...item,
              show: true,
            });
          } else {
            arr.push(item);
          }
        } else {
          arr.push(item);
        }
      });
      if (checkExsts) {
        // dispatch(setPathNameClicked(false))
        setPathList(arr);
      }
    }
  }
  return (
    <Box sx={{ display: "flex", transition: "all 0.9 ease !important" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={isopensidemenu}
        drawerwidth={drawerwidth}
        sidemenutheme={sidemenutheme}
        appbarTheme={appbarTheme}
      >
        <Box
          sx={{
            display: "flex",
            // justifyContent: "space",
            alignItems: "center",
            width: "100% !important",
          }}
        >
          <AppBarItems
            logout={logout}
            getTitle={() => getAppBarTitle(pathsList)}
            open={isopensidemenu}
          />
        </Box>
      </AppBar>
      <Drawer
        variant="permanent"
        open={isopensidemenu}
        drawerwidth={drawerwidth}
        sidemenutheme={sidemenutheme}
      >
        <IconButton
          onClick={() => {
            setOpen(!open);
            dispatch(setIsOpenSideMenu(!isopensidemenu));
            if (!isopensidemenu) {
              dispatch(setCurrentlyOpendMenuItem(copyOfcurrentOpenMenuItem))
              handleOpenClosedMenu()
            }
          }}
          sx={{
            width: "25px",
            height: "25px",
            borderRadius: "50%",
            border: "1px dashed rgba(145, 158, 171, 0.24)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "30px",
            right: "-12.5px",
            zIndex: "1222",
            "& .MuiSvgIcon-root": {
              width: "19px",
              height: "19px",
            },
            backgroundColor: "#fff",
            "&:hover": {
              backgroundColor: "#fff",
            },
          }}
        >
          {isopensidemenu ? <KeyboardArrowLeftIcon /> : <ChevronRightIcon />}
        </IconButton>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: isopensidemenu ? "flex-start" : "center",
            alignItems: "center",
            marginTop: "17px",
            flexWrap: "wrap",
            paddingLeft: isopensidemenu ? "20px" : "0px",
            "& img": {
              objectFit: !isopensidemenu ? "scale-down" : "contain !important",
            },
          }}
        >
          <img
            src={!isopensidemenu ? animationLogo : animationLogo}
            style={{
              width: !isopensidemenu ? "41px" : "78px",
              height: !isopensidemenu ? "41px" : "78px",
              objectFit: !isopensidemenu ? "scale-down" : "contain",
              backgroundSize: "center center",
              position: "relative",
              top: !isopensidemenu ? "-2px" : "0px",
              transform: !isopensidemenu ? "scale(1)" : "scale(1)",
            }}
          />
          {!isopensidemenu ? null : (
            <div style={{position:'relative',top:'5px'}}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "2px" }}
              >
                <span className="gradient-text" style={{
                  '--bg':globalTheme.secondColor
                }}>شركة</span>
                <span className="gradient-text" style={{
                  '--bg':globalTheme.secondColor
                }}>توزيع</span>
                <span className="gradient-text" style={{
                  '--bg':globalTheme.secondColor
                }}>المنتجات النفطية</span>
              </div>
              <span
                style={{
                  fontSize: "12px",
                  color: "grey",
                  fontWeight: "bold",
                  position: "relative",
                  top: "-7px",
                }}
              >
                منصة تكييل المركبات
              </span>
            </div>
          )}
        </Box>
        {isopensidemenu ? (
          <Box
            sx={{
              marginTop: "40px",
              width: "97%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              navigate(`${mainpath}/user-profile`);
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                backgroundColor: "#F2F3F5",
                width: "90%",
                borderRadius: "5px",
                padding: "10px",
                boxShadow: "0px 0px 5px -3px rgb(0 0 0 / 75%)",
                WebkitBoxShadow: "0px 0px 5px -3px rgb(0 0 0 / 75%)",
                MozBoxShadow: "0px 0px 5px -3px rgb(0 0 0 / 75%)",
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "rgb(223,226,231)",
                  padding: "10px",
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 15px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={userData?.image_url ? userData?.image_url : userTemImage}
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "cover",
                    backgroundSize: "center center",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <span style={{ fontFamily: "Cairo-Bold", color: "#000" }}>
                  {localStorage?.getItem("userData") ? userData?.username : ""}
                </span>
                <span
                  style={{
                    fontFamily: "Cairo-Bold",
                    fontSize: "13px",
                    color: "gray",
                  }}
                >
                  {localStorage?.getItem("userData") ? userData?.email : ""}
                </span>
              </Box>
            </Box>
          </Box>
        ) : null}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
            // overflowY:'scroll',
            overflow: "hidden",
            marginTop: !isopensidemenu ? "20px" : "0",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              overflowY:"auto !important",
              overflowX: "hidden",
              transition: "all 1s ease-in-out",
              scrollBehavior: "smooth",
              "&:hover": {
                // overflowY: "auto",
              },
            }}
            ref={MainContainerRef}
          >
            <SideMenuItemArray
              open={isopensidemenu}
              setOpen={(value) => {
                dispatch(setIsOpenSideMenu(value));
              }}
              pathsList={pathsList}
              setPathList={setPathList}
              MainContainerRef={MainContainerRef}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: isopensidemenu ? "start" : "center",
              flexDirection: "column",
              width: "90%",
              alignItems: isopensidemenu ? "start" : "center",
              "& .iconSideMenu": {
                color: `${sidemenutheme?.iconColor}`,
                fontSize: "28px",
              },
              "& .MuiListItemText-root .Mui51Typography-root,& .logout": {
                textAlign: "left",
                font: "normal normal 15px/30px Cairo-Medium !important",
                letterSpacing: "0px",
                color: "#52575D",
                opacity: "1",
              },
              "& .logout": {
                margin: "0 7px !important",
                position: "relative",
                top: "-2px",
                color: "#fff !important",
              },
              transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
            }}
          >
            <Divider
              sx={{
                width: !isopensidemenu ? "60%" : "95%",
                height: "3px",
                background: "#EBF2F7 0% 0% no-repeat padding-box",
                margin: "0px auto",
                marginTop: "5px",
                // marginRight:open?0:'15px',
                opacity: "1",
                marginLeft: isopensidemenu ? "15px" : "17px",
                display: "flex",
                // alignSelf:'center'
              }}
            />

            <Box
              sx={{
                marginLeft: !isopensidemenu ? "0px" : "12px",
                padding: "15px 10px",
                display: "flex !important",
                justifyContent: isopensidemenu ? "start" : "center",
                alignItems: "center",
                cursor: "pointer",
                width: isopensidemenu ? "97%" : "87%",
                borderRadius: "5px",
                backgroundColor: globalTheme.secondColor,
                marginTop: "5px",
              }}
              onClick={logout}
            >
              <LogoutIcon
                // sx={{ transform: " matrix(-1, 0, 0, -1, 0, 0)" }}
                className="iconSideMenu"
                sx={{ color: "#fff !important" }}
              />
              {isopensidemenu ? (
                <span
                  className="logout"
                  sx={{
                    opacity: isopensidemenu ? 1 : 0,
                    margin: "0 10px !important",
                    color: "#fff",
                  }}
                >
                  {t("SIDEMENU.logout")}
                </span>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginTop:
            window.location.pathname ==
            "/admin/available-compounds/compound-map"
              ? "0px"
              : "5px",
          // backgroundColor: "#fff",
          // paddingRight:0,
          // paddingLeft:0,
          minWidth: "900px !important",
        }}
        onClick={() => {
          dispatch(setIsOpenSideMenu(false));
        }}
      >
        {globalLoading ? <Preloading /> : null}
        <Outlet />
      </Box>
    </Box>
  );
}
