import { Box } from "@mui/system";
import React, { useState } from "react";
import userTemImage from "../../assets/img/user.png";
import PopupProfile from "./PopupProfile";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { useNavigate } from "react-router";
import NotificationMenu from './NotificationMenu'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import helpers from "../../assets/js/helper";
import { toast } from "react-toastify";

function AppBarItems(props) {
  const [t] = useTranslation("common");
  const RoleRedux = useSelector(state => state.rolesData.roles)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userData = useSelector((state) => state.userData.data);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const copyFastLink = () => {
    helpers.copyCodeToClipboard();
    const textToCopy = window.location.origin + `/guest-user-order`;
    // Create a temporary textarea element
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    textarea.setAttribute("readonly", "");
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);

    // Copy the text to the clipboard
    textarea.select();
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log("Text copied to clipboard");
        toast.success('تم نسخ الرابط بنجاح!')
      })
      .catch((err) => {
        console.error("Error copying text to clipboard: ", err);
        toast.warn('فشل في نسخ الرابط!')
      });
    // Clean up
    document.body.removeChild(textarea);
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <span style={{ fontFamily: "Cairo-Bold", color: "#000", marginRight: !props?.open ? '123px' : '310px', fontSize: '20px' }}>
            {props?.getTitle()}
          </span>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            justifyContent: 'flex-end',
            transition: "all 0.7 ease-in-out !important",

          }}
        >
          <Box
            sx={{
              backgroundColor: "rgb(223,226,231)",
              padding: "10px",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 4px",
              marginRight: "15px",
              overflow: "hidden",
              cursor: "pointer",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <img
              src={userData?.image_url ? userData?.image_url : userTemImage}
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                objectFit: "cover",
                backgroundSize: "center center",
              }}
            />
          </Box>
          {RoleRedux?.view_user?.value ? <Box
            sx={{
              // backgroundColor: "rgb(223,226,231)",
              padding: "10px",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 4px",
              overflow: "hidden",
              cursor: "pointer",
              "&:hover": {
                transform: "scale(1.1)",
                backgroundColor: "rgb(223,226,231)",
              },
            }}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={() => {
              navigate("users");
            }}
          >
            <PeopleAltIcon
              color="rgb(99,115,129)"
              sx={{ color: "rgb(99,115,129)" }}
            />
          </Box> : null}
          {RoleRedux?.show_notification?.value ? <NotificationMenu /> : null}
          <Box
            sx={{
              width: 'fit-content',
              borderRadius: '3%',
              padding: '5px 15px',
              backgroundColor: '#f3f3f3',
              cursor: 'pointer',
              color: " #156823",
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
            }}
            onClick={copyFastLink}
          >
            <ContentCopyIcon />
            <span >رابط سريع</span>
          </Box>
        </Box>
      </Box>
      <PopupProfile
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        open={open}
        handleClick={handleClick}
        handleClose={handleClose}
        logout={props?.logout}
      />
    </>
  );
}

export default AppBarItems;
